import React, {memo, useCallback, useEffect, useState, useRef} from 'react';
import {
    StyleSheet, 
    KeyboardAvoidingView,
    View, 
    ScrollView, 
    TouchableOpacity, 
    Text, 
    Dimensions, 
    TextInput, 
    FlatList,
    Image,
    Animated
} from 'react-native';
import keyExtractor from '../../ultis/keyExtractor';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import moment from 'moment';
import 'moment/locale/fr';
import SvgHashtag from '../../svgs/Restaurant/SvgHashtag';
import SvgCounter from '../../svgs/Restaurant/SvgCounter';
import ListItem from './components/ShopListItem';
import Radio from '../../components/Radio';
import CheckBox from '../../components/CheckBox';
import SpeRadio from '../../components/SpeRadio';
import {showMessage} from 'react-native-flash-message';
import CartScreen from './components/CartScreen';
import Url from '../../ultis/url';
import {useRoute, useNavigation, useTheme} from '@react-navigation/native';
import ROUTES from '../../ultis/routes';
import Header from './components/Header';
import ThemeUtils from '../../ultis/themeUtils';
// import PhoneInput from "react-native-phone-number-input";
import PhoneInput from 'react-native-phone-input';

import data from '../../ultis/countries';

var qs = require('qs');

const {width, height} = Dimensions.get('window');

const getDate = (date) => {
  return `Le ${moment(date).format('L')} à ${moment(date).format('LT')}`;
}
function iterableArray (item){
    if(item != undefined && Array.isArray(item)){
        return item
    } else {
        return []
    }
}
 
function iterableObject (item){
    if(item != undefined && item != null && typeof item === 'object'){
        return item
    } else {
        return {}
    }
}

const Restaurant = memo(() => {
const navigation = useNavigation();
const route = useRoute()
const path = window.location.search
var obj2 = qs.parse(path, { ignoreQueryPrefix: true });
const a = obj2?.eventId ?? NaN
const b = obj2?.sessionId ?? NaN
const c = obj2?.tableId ?? NaN

let sessionId = !isNaN(b ?? NaN) && b != '' ? parseInt(b) : null
let eventId = !isNaN(a ?? NaN) && a != '' ? parseInt(a) : null
let tableId = !isNaN(c ?? NaN) && c != '' ? parseInt(c) : null

const scrollY = new Animated.Value(0);

  let scrollview_ref = useRef(null);
  const {colors, dark} = useTheme()
  const theme = useTheme()
  const [forEdit, setForEdit] = useState(false)
  const [openCode, setOpenCode] = useState(false)
  const [openScanner, setOpenScanner] = useState(false)
  const [option, setOption] = useState("onBar")
  const [table, setTable] = useState(tableId)
  const [code, setCode] = useState('')
  const [openOptionScreen, setOpenOptionScreen] = useState(false)
  const [event, setEvent] = useState({} );
  const [session, setSession] = useState({} );
  const [categories, setCategories] = useState([] );
  const [products, setProduts] = useState([] );
  const [menus, setMenus] = useState([] );
  const [grouped, setGrouped] = useState([] );
  const [obj, setObj] = useState({} );
  const [keys, setKeys] = useState([] );
  const [spes, setSpes] = useState(null);
  const [m, setM] = useState(null);
  const [forNumber, setForNumber] = useState(false);
  const [phone, setPhone] = useState('+33');
  const [ticketsArr, setTickets] = useState([] );
  const [quantitiesProduct, setQuantitiesProduct] = useState([] );
  const [quantities, setQuantities] = useState([] );
  const [quantitiesM, setQuantitiesM] = useState([] );
  const [quantitiesMenu, setQuantitiesMenu] = useState([] );
  const [restaurant, setRestaurant] = useState({} );
  const [cart, setCart] = useState({} );
  const [productId, setProductId] = useState(null)
  const [menuId, setMenuId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [exist, setExist] = useState(false)
  const [goToPay, setGoToPay] = useState(false)
  const [loading, setLoading] = useState(true)
  const [verifyCode, setVerifyCode] = useState('')
  const [isInit, setIsInit] = useState(true)
  const [forVerify, setForVerify] = useState(false)
  const [notes, setNotes] = useState('')
  const [isActivated, setIsActivated] = useState(true)
  const [user, setUser] = useState(firebase.auth().currentUser)
  const [showCart, setShowCart] = useState(false)
  const [isGrouped, setIsGrouped] = useState(false)
  const [isPaid, setIsPaid] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [groupId, setGroupId] = useState('')
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [address, setAddress] = useState('')
  const eventRef =  firebase.database().ref('events');
  const ticketRef =  firebase.database().ref('tickets');
  const restaurantRef =  firebase.database().ref('restaurants');
  const [uid, setUid] = useState('');
  const [accessToken, setToken] = useState(null);
  const orderRef = firebase.firestore().collection('orders')
  const phoneInput = useRef(null);


  let arr  = []

  useEffect(() => {
    firebase.auth().languageCode = 'fr';
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user)
        setUid(user.uid)
        user.getIdTokenResult().then((token) => {
          setToken(token.token)
        })
        .catch(err => console.log(err))

      } else {
        
      }
    });  
 
       restaurantRef.orderByChild('sessionId').equalTo(sessionId).once('value', snap => {
        let restaurants = []
          //@ts-ignore
          snap.forEach((row) => {
            restaurants.push(row.val())
          })
          if(restaurants?.length){
            const restaurant = restaurants[0]
            setIsOpen(restaurant.isOpen ?? false)
            setRestaurant(restaurant)
            setExist(true)
          }
          setLoading(false)
      })
    eventRef.orderByChild('id').equalTo(eventId).once('value',(snap) => {
        setIsInit(!isInit)
        setIsActivated(isActivated)
    })
    return () => eventRef.orderByChild('id').equalTo(eventId).off()
  }, [])
  
  useEffect(() => {
    if(isActivated != isInit){
        eventRef.orderByChild('id').equalTo(eventId).once('value',(snap) => {
            let events = []
            snap.forEach(row => {
                events.push(row.val())
            })
            if(events.length > 0){
                const event = events[0]
                let products = []
                let menus = []
                let idsProd = []
                setEvent(event)
                setGroupId(event.groupId)
                if(event?.categories){
                    setCategories(event?.categories)
                }
                if(event?.products){
                  products = [...event?.products?.filter((item) => !item?.disabled && ((item?.product?.sessions?.length && item?.product?.sessions?.includes(sessionId)) || item?.product?.onAllSessions))]
                  setProduts(products)
                }
                
                if(event?.menus){
                    const productsId = products.map((i) => i.id)
                    menus = [...event?.menus?.map((i) => {
                            return {
                            ...i,
                            menu: {
                                ...i.menu,
                                ...(!i?.menu?.isUnlimited ? {p_realAvailable: getAvailable2(i, products)} : {})
                            }
                        }
                    }).filter((item) => 
                        !item?.menu?.priceItems &&
                        !item?.disabled && 
                        (item?.menu?.isUnlimited || (typeof item?.menu?.p_realAvailable == "number" && item?.menu?.p_realAvailable > 0) )&&
                        getStock(item, products) == null && getStock(item, products) != 0 &&
                        (!item?.menu?.menuItems?.find((i) => !productsId?.includes(i?.productId)) || !item?.menu?.options?.find((i) => (!i?.forProduct && !productsId?.includes(i?.related?.id) || (i?.forProduct && i?.listItems?.find((it) => !productsId?.includes(it?.item?.productId)))))))]
                    setMenus(menus)
                }

                products = products.filter((item) => item?.product?.onApp)
    
                let quantitiesP = []
                    const a = products.forEach((item) => {
                        if(((item?.product?.sessions?.length && item?.product?.sessions?.includes(sessionId)) || item?.product?.onAllSessions)){
                            quantitiesP = [...iterableArray(quantitiesP),{
                                quantity: 0,
                                session: true,
                                id: item.id,
                                isFree: item?.product?.isFree ? item.product.isFree : false,
                                price: item?.product?.p_price ? item.product.p_price : null,
                                costPrice: item?.product?.p_costPrice ? item?.product?.p_costPrice : null,
                                spe: []
                            }]
                        }
                    })
                    if(quantitiesProduct?.findIndex((i) => i?.quantity > 0) > -1){
                        const ids = quantitiesP.map((i) => i?.id)
                        let quantitiesPro = [...iterableArray(quantitiesProduct.filter((i ) => ids.includes(i.id)).map(i => {
                                const quantityP = quantitiesP.find(it => it.id == i.id)
                               return {
                                    ...quantityP,
                                    spe: i?.spe ? i?.spe : [],
                                    quantity: i?.quantity
                                }
                            }))]
                        const ids2 = quantitiesPro.map(i => i?.id)
                        let quantitiesPro2 = [...iterableArray(quantitiesP.filter((i ) => !ids2.includes(i.id)))]
                        quantitiesP = [...quantitiesPro, ...quantitiesPro2]
                        if(quantitiesP?.find((i) => !getQuantitiesInit([...products, ...menus], i?.id, true)  && i?.quantity > 0)){
                            quantitiesP = quantitiesP.map((i) => {
                                const product = products.find((it) => it?.id == i?.id)
                                const a = getQuantitiesInit([...products, ...menus], i?.id, true)
                                if(!a  && i?.quantity > 0){
                                    showMessage({
                                      message: `La disponibilité du produit ${product?.product?.p_name} a changé. Certains éléments du panier ne sont plus disponibles.`,
                                      type: 'warning',
                                      icon: 'warning',
                                    });
                                }
                                return a ?  i : {
                                    ...i,
                                    quantity: 0,
                                    spe: []
                                }
                            })
                            
                        }
                    }
                    setQuantitiesProduct(quantitiesP)
                
    
                let quantitiesM = []
                    const b = menus.forEach((item) => {
                        quantitiesM = [...iterableArray(quantitiesM),{
                            menu: true,
                            quantity: 0,
                            id: item.id,
                            isFree: item?.menu?.isFree ? item.menu.isFree : false,
                            price: item?.menu?.p_price ? item.menu.p_price : null,
                            costPrice: item?.menu?.p_costPrice ? item?.menu?.p_costPrice : null,
                            menus: [],
                            spe: []
                        }]
                        
                    })
                    if(quantitiesMenu?.findIndex((i) => i?.quantity > 0) > -1){
                        const ids = quantitiesM.map((i) => i?.id)
                        let quantitiesMe = [...iterableArray(quantitiesMenu.filter((i) => ids.includes(i.id)).map(i => {
                                const quantityM = quantitiesM.find(it => it.id == i.id)
                               return {
                                    ...quantityM,
                                    menus: i?.menus ? i?.menus : [],
                                    spe: i?.spe ? i?.spe : [],
                                    quantity: i?.quantity
                                }
                            })
                        )]
                        const ids2 = quantitiesMe.map((i) => i?.id)
                        let quantitiesMe2 = [...iterableArray(quantitiesMe.filter(i => !ids2.includes(i.id)))]
                        quantitiesM = [...quantitiesMe, ...quantitiesMe2]
                       
                        if(quantitiesM?.find((i) => {
                            const menu = menus?.find((it) => it?.id == i?.id)
                            return ((!menu?.menu?.isUnlimited && typeof menu?.menu?.p_realAvailable == 'number' &&
                            menu?.menu?.p_realAvailable <
                              i?.quantity) || (getQMenu2(i?.id, [...products, ...menus]) && i?.quantity > 0) || (!menu?.menu?.isUnlimited && menu?.menu?.p_available < i?.quantity))
                        })){
                            quantitiesM = quantitiesM.map((i) => {
                                const menu = menus?.find((it) => it?.id == i?.id)
  
                                const a = ((!menu?.menu?.isUnlimited && typeof menu?.menu?.p_realAvailable == 'number' &&
                                            menu?.menu?.p_realAvailable <
                                              i?.quantity) || (getQMenu2(i?.id, [...products, ...menus]) && i?.quantity > 0) || (!menu?.menu?.isUnlimited && menu?.menu?.p_available < i?.quantity))
                                if(a && i?.quantity > 0){
                                    showMessage({
                                      message: `La disponibilité du produit ${menu?.menu?.p_name} a changé. Certains éléments du panier ne sont plus disponibles.`,
                                      type: 'warning',
                                      icon: 'warning',
                                    });
                                }
                                return !a ?  i : {
                                    ...i,
                                    quantity: 0,
                                    menus: [],
                                    spe: [],
                                }
                            })
                            
                        }
                    }
                    setQuantitiesMenu(quantitiesM)
                
    
                const list = [...products, ...menus].filter((item) => (item?.product || item?.menu) && (item?.product?.p_realAvailable > 0 || item?.menu?.p_realAvailable > 0 || item?.product?.isUnlimited || item?.menu?.isUnlimited)).map(item => {
                    return {
                        ...item,
                        category: item?.menu?.p_category && findCategory2(item?.menu?.p_category, event?.categories) ? findCategory2(item?.menu?.p_category, event?.categories): item?.product?.p_category &&  findCategory2(item?.product?.p_category, event?.categories) ? findCategory2(item?.product?.p_category, event?.categories) : item?.menu ? "Menus" : "Produits"
                    }
                })
                setSession(event?.sessions?.find((i) => i.id == sessionId))
                const obj = groupBy(list, 'category')
                const keys = Object.keys(obj)
                const grouped = Object.values(obj)
                setObj(obj)
                setKeys(keys)
                setGrouped(grouped)
            }
            setIsInit(true)
            setIsActivated(true)
        })
    }
    
  }, [isActivated, isInit])

  const findCategory = (category_id) => {
    const cat = categories.find((category) => category.id == category_id);
    if(cat !== undefined){
        return cat.category;
    }
    return null
  }
  const findCategory2 = (category_id, categories) => {
    const cat = categories.find((category) => category.id == category_id);
    if(cat !== undefined){
        return cat.category;
    }
    return null
  }

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const getQuantitiesInit = (p ,id , isProduct = false) => {
    let arr = []
    const a = p.forEach(i => {
      if(i?.menu && isProduct){
        const q = quantitiesMenu.find(item => item.id == i?.id)
        if(q){
          const b = i?.menu?.menuItems?.forEach(it => {
            let ar = {
              id: it?.productId,
              quantity: it?.quantity * q.quantity,
              product: true
            }
            arr.push(ar)
          })
          const c = q?.menus?.forEach(it => {
            const d = it?.menu?.forEach(item2 => {
                if(item2?.optionsProduct){
                  let ar = {
                    id: item2?.productId,
                    quantity: 1,
                    product: true
                  }
                  arr.push(ar)
                }
            })
          })
        }
      }
      if(i?.product && isProduct){
        const q = quantitiesProduct.find(item => item.id == i?.id)
        if(q){
            let ar = {
              id: q?.id,
              quantity: q.quantity,
              product: true
            }
            arr.push(ar)
        }
      }
     
    })
    let quantity = 0
    const g = arr?.filter(item => item?.product == isProduct && item?.id == id)?.forEach(item => {
      quantity = quantity + item.quantity
    })
      let isGood = false
      if(isProduct){
          const product = p?.find(i => i?.product && i?.id == id)
          if(product){
              isGood = product?.product?.isUnlimited ? true : typeof product?.product?.p_realAvailable == 'number' ? product?.product?.p_realAvailable >= quantity : product?.product?.p_available >= quantity
          }
      }
      if(!isProduct){
          const price = p?.find(i => i?.price && i?.id == id)
          if(price){
              isGood = price?.price?.isUnlimited ? true : typeof price?.price?.p_realAvailable == 'number' ? price?.price?.p_realAvailable >= quantity : price?.price?.p_available >= quantity
          }
      }
    return isGood
  }

  const getCountry = async (country) => {
    const countryData = await data
    try {
      const countryCode = await countryData.filter(
        obj => obj.code === country.toUpperCase()
      )[0].dial_code
      // Set data from user choice of country
      setPhone(countryCode)
    }
    catch (err) {
      console.log(err)
    }
  }



  const getQMenu2 = (menuId, p) => {
    const menu = p?.find((i) => i?.menu && i?.id == menuId)
    if(menu){
      let bool = null
      const a = menu?.menu?.menuItems?.forEach((i) => {
        if(i?.productId){
          const product = p?.find((it) => it?.product && it?.id == i?.productId)
          const a = !getQuantitiesInit(p, i?.productId ? i?.productId : i?.priceId, i?.productId ? true : false) 
          if(bool){

          } else if(a){
            bool = a
          } else {
            bool = false
          }
        }
      })
      if(bool == null){
        return true
      } else {
        return bool
      }
    } else {
      return true
    }
  }

  const getStock = (productSource, products2) => {
    if(productSource?.menu){
      let arr  = []
    
      const b = productSource?.menu?.options?.filter((item ) => item?.forProduct)?.forEach(i => {
        if(i?.type == 2){
            const c = i?.listItems?.filter((ite) => {
              const product = products2?.find((item3 ) => ite?.item?.productId == item3?.id)
              return product?.product?.isUnlimited || (typeof product?.product?.p_realAvailable == 'number' && product?.product?.p_realAvailable > 0) || (typeof product?.product?.p_realAvailable != 'number' && product?.product?.p_available > 0)  
            })
            if(c?.length < i?.min){
              arr.push(0)
            }
        }
        if(i?.type == 3){
          const c = i?.listItems?.filter((ite ) => {
            const product = products2?.find((item3) => ite?.item?.productId == item3?.id)
            return product?.product?.isUnlimited || (typeof product?.product?.p_realAvailable == 'number' && product?.product?.p_realAvailable > 0) || (typeof product?.product?.p_realAvailable != 'number' && product?.product?.p_available > 0)  
          })
          if(c?.length < 1){
            arr.push(0)
          }
      }
      })
      if(arr?.length == 0){
        return null
      } else {
        return Math.min(...arr)
      }
    }
    return null
  }

  const getQuantities = (id ) => {
    let arr = []
    const a = [...products, ...menus].forEach(i => {
      if(i?.menu){
        const q = quantitiesMenu.find((item) => item.id == i?.id)
        if(q){
          const b = i?.menu?.menuItems?.forEach((it) => {
            let ar = {
              id: it?.productId,
              quantity: it?.quantity * q.quantity,
              product: true
            }
            arr.push(ar)
          })
          const c = q?.menus?.forEach((it) => {
            const d = it?.menu?.forEach((item2) => {
                if(item2?.optionsProduct){
                  let ar = {
                    id: item2?.productId,
                    quantity: 1,
                    product: true
                  }
                  arr.push(ar)
                }
            })
          })
        }
      }
      if(i?.product){
        const q = quantitiesProduct.find((item) => item.id == i?.id)
        if(q){
            let ar = {
              id: q?.id,
              quantity: q.quantity,
              product: true
            }
            arr.push(ar)
        }
      }
 
    })
    let quantity = 0
    const g = arr?.filter((item) => item?.id == id)?.forEach((item) => {
      quantity = quantity + item.quantity
    })
    return quantity
  }

  const getAvailable = (productSource) => {
 
    if(productSource?.menu){
      let arr = []
      const a  = productSource?.menu?.menuItems?.forEach((i) => {
        if(i?.productId){
          const product = products?.find((it) => it?.id == i?.productId)
          if(product){
            const realQ = typeof product?.product?.p_realAvailable == 'number' ? product?.product?.p_realAvailable : product?.product?.p_available ? product?.product?.p_available : 0
            const q = i?.quantity
            const c = Math.trunc(q == 0 ? 0 :realQ/q)

            arr.push(c < realQ ? c : realQ)
          } 
        }
      })
      if(arr?.length == 0){
        return 0
      } else {
        return Math.min(...arr,productSource?.menu?.p_realAvailable ? productSource?.menu?.p_realAvailable : productSource?.menu?.p_available)
      }
    }
  }

  const getAvailable2 = (productSource, products) => {
 
    if(productSource?.menu){
      let arr = []
      const a  = productSource?.menu?.menuItems?.forEach((i) => {
        if(i?.productId){
          const product = products?.find((it) => it?.id == i?.productId)
          if(product && !product?.product?.isUnlimited){
            const realQ = typeof product?.product?.p_realAvailable == 'number' ? product?.product?.p_realAvailable : product?.product?.p_available ? product?.product?.p_available : 0
            const q = i?.quantity
            const c = Math.trunc(q == 0 ? 0 :realQ/q)

            arr.push(c < realQ ? c : realQ)
          } 
        }
      })
      
        return Math.min(...arr,typeof productSource?.menu?.p_realAvailable == 'number' ? productSource?.menu?.p_realAvailable : productSource?.menu?.p_available)
    }
  }

  const getQMenu = (menuId) => {
    const menu = menus?.find((i) => i?.id == menuId)
    if(menu){
      let bool = null
      const a = menu?.menu?.menuItems?.forEach((i) => {
        if(i?.productId){
          const product = products?.find((it) => it?.id == i?.productId)
          if(product && !product?.product?.isUnlimited){
            const a = getQuantities(i?.productId) + i?.quantity > (typeof product?.product?.p_realAvailable == 'number' ? product?.product?.p_realAvailable : product?.product?.p_available)
            if(bool){

            } else if(a){
              bool = a
            } else {
              bool = false
            }
        }
        } 
      })
      if(bool == null){
        return false
      } else {
        return bool
      }
    } else {
      return true
    }
  }


  const addQuantities = (id = null, speAdd = null) => {
      let spe = [...iterableArray(quantities)]
      const qProd = [...quantitiesProduct]
      const it = qProd.find((i) => i.id == (productId ? productId : id))
      const index = qProd.findIndex((i) => i.id == (productId ? productId : id))
      const date = new Date().getTime()
      if(it != undefined){
        let arr = {}
        if(speAdd){
            spe = [...iterableArray(speAdd)]
        }
        arr = {
            ...it,
            quantity: it.quantity + 1,
            spe: [...it.spe, {id: date, spe}]
        }
        qProd.splice(index,1,arr)
        setQuantitiesProduct(qProd)
      }
      reinit()
  }

  const groupByArr = (arr) => {
    return arr.reduce((s, f) => {
        let index = f.index;
        index = index.split('-')
        index.pop()
        index = index.join("-")
        s[index] = s[index] ?? [];
        s[index].push(f);
        return s;
      }, {});
  }

  const onBack = useCallback(() => {
    setGoToPay(false);
  }, []);

  const addQuantitiesMenu = (id = null, speMenu = []) => {
    let spe = [...iterableArray(quantities)]
    let menu = [...iterableArray(quantitiesM)]
    const qMenu = [...quantitiesMenu]
    const it = qMenu.find((i) => i.id == (menuId ? menuId : id))
    const index = qMenu.findIndex((i) => i.id == (menuId ? menuId : id))
    const date = new Date().getTime()
    const b = menus?.find((i) => i.id == (menuId ? menuId : id))?.menu?.options?.filter((i) => !i?.forProduct && i?.listItems?.length > 0)
    const c = menus?.find((i) => i.id == (menuId ? menuId : id))?.menu?.menuItems
    const d = menus?.find((i) => i.id == (menuId ? menuId : id))?.menu?.options?.filter((i) => i?.forProduct && i?.listItems?.length > 0)
    let isGood = true
    let isGood3 = true
    let init
    let arr = []

    if(speMenu.length){
        const hasMenu = speMenu.find((i) => i?.menu)
        const hasSpe = speMenu.find((i) => i?.spe)
        if(hasMenu){
            menu = hasMenu?.menu
        } else {
            return
        }
        if(hasSpe){
            spe = hasSpe?.spe
        }
    }

    if(b?.find((it) => it?.type == 2) && menu){
        let filtredItems = menu?.filter((i) => i?.optionsItem && i?.type == 2)
        let it = {}
        const items = b.filter((it) => it?.type == 2)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
              if(i[0].optionId == it?.id){
                const k = i?.length > (it?.min ? it.min - 1 : 0);
                if (!k) {
                isGood = false;
                }
              }
                
            })
            if(!isGood){
                break
            }
        }
        let z = 0
        const y = items.map((i) => i?.related?.id)
        const g = c?.forEach((i) => {
            if(y?.includes(i?.productId)){
                z = z + (i?.quantity ? i.quantity : 0)
            }
        })
        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood = false
        }
       
    }


    if((!isGood)){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    let isGood2 = true

    if(b?.find((it) => it?.type == 3) && menu){
        let filtredItems = menu?.filter((i) => i?.optionsItem && i?.type == 3)
        let it = {}
        const items = b.filter((it) => it?.type == 3)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
              if(i[0].optionId == it?.id){
                const k = i?.length > 0;
                if (!k) {
                isGood2 = false;
                }
              }
            })
            if(!isGood2){
                break
            }
        }
        let z = 0
        const y = items.map((i) => i?.related?.id)
        const g = c?.forEach((i) => {
            if(y?.includes(i?.productId)){
                z = z + (i?.quantity ? i.quantity : 0)
            }
        })

        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood2 = false
        }
       
    }
    if(!isGood2){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    if(d?.find((it) => it?.type == 2) && menu){
        let filtredItems = menu?.filter((i) => i?.optionsProduct && i?.type == 2)
        let it = {}
        const items = d.filter((it) => it?.type == 2)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
              if(i[0].optionId == it?.id){
                const k = i?.length > (it?.min ? it.min - 1 : 0);
                if (!k) {
                isGood3 = false;
                }
              }                
                
            })
            if(!isGood3){
                break
            }
        }
        let z = 0

        const r = items?.forEach((i) => {
            z = z + 1
        })

        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood3 = false
        }
       
    }


    if((!isGood3)){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    let isGood4 = true

    if(d?.find((it) => it?.type == 3) && menu){
        let filtredItems = menu?.filter((i) => i?.optionsProduct && i?.type == 3)
        let it = {}
        const items = d.filter((it) => it?.type == 3)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
                const k = i[0].optionId == it?.id && i?.length > 0
                if(!k){
                    isGood4 = false
                }
            })
            if(!isGood4){
                break
            }
        }
        let z = 0

        const r = items?.forEach((i) => {
            z = z + 1
        })
        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood4 = false
        }
       
    }
    if(!isGood4){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    if(it != undefined){
      let arr = {}

      arr = {
          ...it,
          quantity: it.quantity + 1,
          spe: [...iterableArray(it.spe), {id: date, spe}],
          menus: [...iterableArray(it.menus), {id: date, menu}]
      }
      qMenu.splice(index,1,arr)
      setQuantitiesMenu(qMenu)
    }
    reinit()
}

const editQuantitiesMenu = (id = null) => {
    const spe = [...iterableArray(quantities)]
    const menu = [...iterableArray(quantitiesM)]
    const qMenu = [...quantitiesMenu]
    const it = qMenu.find((i) => i.id == (menuId ? menuId : id))
    const index = qMenu.findIndex((i) => i.id == (menuId ? menuId : id))
    const date = new Date().getTime()
    const b = menus?.find((i) => i.id == (menuId ? menuId : id))?.menu?.options?.filter((i) => !i?.forProduct && i?.listItems?.length > 0)
    const c = menus?.find((i) => i.id == (menuId ? menuId : id))?.menu?.menuItems
    const d = menus?.find((i) => i.id == (menuId ? menuId : id))?.menu?.options?.filter((i) => i?.forProduct && i?.listItems?.length > 0)
    let isGood = true
    let isGood3 = true
    let init
    let arr = []

    if(b?.find((it) => it?.type == 2)){
        let filtredItems = menu?.filter((i) => i?.optionsItem)
        let it = {}
        const items = b.filter((it) => it?.type == 2)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
              if(i[0].optionId == it?.id){
                const k = i?.length > (it?.min ? it.min - 1 : 0);
                if (!k) {
                isGood = false;
                }
              }
                
            })
            if(!isGood){
                break
            }
        }
        let z = 0
        const y = items.map((i) => i?.related?.id)
        const g = c?.forEach((i) => {
            if(y?.includes(i?.productId)){
                z = z + (i?.quantity ? i.quantity : 0)
            }
        })
        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood = false
        }
       
    }


    if((!isGood)){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    let isGood2 = true

    if(b?.find((it) => it?.type == 3)){
        let filtredItems = menu?.filter((i) => i?.optionsItem && i?.type == 3)
        let it = {}
        const items = b.filter((it) => it?.type == 3)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
              if(i[0].optionId == it?.id){
                const k = i?.length > 0;
                if (!k) {
                isGood2 = false;
                }
              }
                
            })
            if(!isGood2){
                break
            }
        }
        let z = 0
        const y = items.map((i) => i?.related?.id)
        const g = c?.forEach((i) => {
            if(y?.includes(i?.productId)){
                z = z + (i?.quantity ? i.quantity : 0)
            }
        })

        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood2 = false
        }
       
    }
    if(!isGood2){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    if(d?.find((it) => it?.type == 2)){
        let filtredItems = menu?.filter((i) => i?.optionsProduct && i?.type == 2)
        let it = {}
        const items = d.filter((it) => it?.type == 2)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
              if(i[0].optionId == it?.id) {
                const k = i?.length > (it?.min ? it.min - 1 : 0)
                if(!k){
                    isGood3 = false
                }
              }
            })
            if(!isGood3){
                break
            }
        }
        let z = 0

        const r = items?.forEach((i) => {
            z = z + 1
        })


        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood3 = false
        }
       
    }


    if((!isGood3)){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    let isGood4 = true

    if(d?.find((it) => it?.type == 3)){
        let filtredItems = menu?.filter((i) => i?.optionsProduct && i?.type == 3)
        let it = {}
        const items = d.filter((it) => it?.type == 3)
        for(it of items){
            const r = Object.values(groupByArr(filtredItems)).forEach((i, index) => {
              if(i[0].optionId == it?.id){
                const k = i?.length > 0;
                if (!k) {
                  isGood4 = false;
                }
              }
                
            })
            if(!isGood4){
                break
            }
        }
        let z = 0

        const r = items?.forEach((i) => {
            z = z + 1
        })
        if(z != Object.values(groupByArr(filtredItems)).length){
            isGood4 = false
        }
       
    }
    if(!isGood4){
        showMessage({
            message: `Des choix obligatoires sont manquants`,
            type: 'danger',
            icon: 'danger',
          })
        return
    }
    if(it != undefined){
      let arr = {}
      if(id == null){
        arr = {
            ...it,
            spe: [...iterableArray(it.spe), {id: date, spe}],
            menus: [...iterableArray(it.menus), {id: date, menu}]
        }
      } else {
        let s = [...iterableArray(it.spe)]
        const sIt = s.find((i) => i.id == id)
        const sIndex = s.findIndex((i) => i.id == id)
        let m = [...iterableArray(it.menus)]
        const mIt = m.find((i) => i.id == id)
        const mIndex = m.findIndex((i) => i.id == id)
          if(sIt != undefined){
            let arr2 = {
                id,
                spe,
                }
            s.splice(sIndex, 1, arr2)
                arr = {
                    ...it,
                    spe: s
                }
             
            } else {
                arr = {
                    ...it,
                } 
            }
            if(mIt != undefined){
                let arr2 = {
                    id,
                    menu,
                    }
                m.splice(mIndex, 1, arr2)
                    arr = {
                        ...it,
                        ...arr,
                        menus: m
                    }
                 
                } else {
                    arr = {
                        ...it,
                    } 
                }
      }
      
      qMenu.splice(index,1,arr)
      setQuantitiesMenu(qMenu)
    }
    reinit()
}

    const getTotal = () => {
        let total = 0
        let b = quantitiesProduct?.forEach((item ) => {
            if(!item.isFree && item?.quantity && item?.price){
                total = total + item.quantity * item.price
            }
            const e = item?.spe?.forEach((item ) => {
                item?.spe?.forEach((item ) => {
                    if(!item.isFree && item?.price){
                        total = total + item.price
                    }
                })
            })
        })
        let c = quantitiesMenu.forEach((item ) => {
            if(!item.isFree && item?.quantity && item?.price){
                total = total + item.quantity * parseInt(item.price)
            }
            const d = item?.menus?.forEach((item ) => {
                const f = item?.menu?.forEach((item ) => {
                    if(!item.isFree && item?.price){
                        total = total + parseInt(item.price)
                    }
                })
            })
            const e = item?.spe?.forEach((item ) => {
                item?.spe?.forEach((item ) => {
                    if(!item.isFree && item?.price){
                        total = total + parseInt(item.price)
                    }
                })
            })
        })
        return Math.round(total * 100)/100
    }

    const getTotal2 = () => {
        let total = 0
        let b = quantitiesProduct?.forEach((item ) => {

            let price = 0
            let q = item?.quantity == item?.spe?.length ? item?.quantity : item?.spe?.length 
            let q2 = item?.quantity - item?.spe?.length
            const e = Array.from(Array(q).keys()).forEach((i) => {
                let price2 = item?.price
                const spe = item?.spe[i]
                const g = spe?.spe?.forEach((it) => {
                    if(item?.isFree && it?.sign == "minus"){

                    } else {
                        if(!it?.isFree && it?.price){
                            price2 = price2 + it.price
                        }
                    }
                })
                price2 = price2 <= 0 ? 0 : price2
                price = price + price2
            })
            if(q2 > 0){
                const f = Array.from(Array(q2).keys()).forEach((i) => {
                    if(!item.isFree && item?.quantity && item?.price){
                        price = price + item.price
                    }
                }) 
            }
            total = total + price
        })
        let c = quantitiesMenu.forEach((item ) => {
            if(!item.isFree && item?.quantity && item?.price){
                total = total + item.quantity * item.price
            }
            const d = item?.menus?.forEach((item ) => {
                const f = item?.menu?.forEach((item ) => {
                    if(!item.isFree && item?.price){
                        total = total + item.price
                    }
                })
            })
            const e = item?.spe?.forEach((item ) => {
                item?.spe?.forEach((item ) => {
                    if(!item.isFree && item?.price){
                        total = total + item.price
                    }
                })
            })
        })
        return Math.round(total * 100)/100
    }

    // const getTotal = () => {
    //     let total = 0
        
    //     let b = quantitiesProduct.forEach((item ) => {

    //         let price = 0
    //         let q = item?.quantity == item?.spe?.length ? item?.quantity : item?.spe?.length 
    //         let q2 = item?.quantity - item?.spe?.length
    //         const e = Array.from(Array(q).keys()).forEach((i) => {
    //             let price2 = item?.price
    //             const spe = item?.spe[i]
    //             const g = spe?.spe?.forEach((it) => {
    //                 if(item?.isFree && it?.sign == "minus"){

    //                 } else {
    //                     if(!it?.isFree && it?.price){
    //                         price2 = price2 + it.price
    //                     }
    //                 }
    //             })
    //             price2 = price2 <= 0 ? 0 : price2
    //             price = price + price2
    //         })
    //         if(q2 > 0){
    //             const f = Array.from(Array(q2).keys()).forEach((i) => {
    //                 if(!item.isFree && item?.quantity && item?.price){
    //                     price = price + item.price
    //                 }
    //             }) 
    //         }
    //         total = total + price
    //     })
    //     let c = quantitiesMenu.forEach((item ) => {
    //         if(!item.isFree && item?.quantity && item?.price){
    //             total = total + item.quantity * item.price
    //         }
    //         item?.menu.forEach((item ) => {
    //             if(!item.isFree && item?.quantity && item?.price){
    //                 total = total + item.quantity * item.price
    //             }
    //         })
    //     })
    //     return Math.round(total * 100)/100
    // }

  const editQuantities = (id = null) => {
    const spe = [...iterableArray(quantities)]
    const qProd = [...quantitiesProduct]
    const it = qProd.find((i) => i.id == productId)
    const index = qProd.findIndex((i) => i.id == productId)
    const date = new Date().getTime()
    if(it != undefined){
      let arr = {}
      if(id == null){
        arr = {
            ...it,
            spe: [...it.spe, {id: date, spe}]
        }
      } else {
          let s = [...iterableArray(it.spe)]
          const sIt = s.find((i) => i.id == id)
          const sIndex = s.findIndex((i) => i.id == id)
          if(sIt != undefined){
            let arr2 = {
                id,
                spe
                }
            s.splice(sIndex, 1, arr2)
                arr = {
                    ...it,
                    spe: s
                }
             
            } else {
                arr = {
                    ...it,
                } 
            }
      }
      
      qProd.splice(index,1,arr)
      setQuantitiesProduct(qProd)
    }
    reinit()
}

const isEditable = (item) => {
    const hasMenuItems = item?.menu && item?.menu?.menuItems && item?.menu?.menuItems?.findIndex((i) => getProduct(i?.productId)?.product?.ingredients?.findIndex((it) => it?.add || (it?.isInitial && it?.remove)) > -1) > -1
    const hasOptions = item?.menu && item?.menu?.options && item?.menu?.options?.findIndex((i) => i?.listItems && i?.listItems?.findIndex((it) => (i?.forProduct) || !i?.forProduct))
    return hasMenuItems || hasOptions
}

const notEmpty = () => {
    return quantitiesMenu.findIndex((i) => i.quantity > 0) > -1 || quantitiesProduct.findIndex((i) => i.quantity > 0) > -1
}

const removeQuantities = (id = null, prodId = null) => {
    const qProd = [...quantitiesProduct]
    const it = qProd.find((i) => i.id == (productId ? productId : prodId))
    const index = qProd.findIndex((i) => i.id == (productId ? productId : prodId))
    if(it != undefined){
      let arr = {}
      if(id == null){
        let spe = [...iterableArray(it.spe)]
        spe.pop()
        arr = {
            ...it,
            quantity: it.quantity -1,
            spe
        }
      } else {
          let s = [...iterableArray(it.spe)]
          const sIt = s.find((i) => i.id == id)
          const sIndex = s.findIndex((i) => i.id == id)
          if(sIt != undefined){
                  s = s.filter((i) => i.id != id)
                  arr = {
                    ...it,
                    quantity: it.quantity - 1,
                    spe: s
                }
            }
      }
      
      qProd.splice(index,1,arr)
      setQuantitiesProduct(qProd)
    }
    reinit()
}

const removeQuantitiesMenu = (id = null, mId = null) => {
    const qMenu = [...quantitiesMenu]
    const it = qMenu.find((i) => i.id == (menuId ? menuId : mId))
    const index = qMenu.findIndex((i) => i.id == (menuId ? menuId : mId))
    if(it != undefined){
      let arr = {}
      if(id == null){
        arr = {
            ...it,
            quantity: it.quantity -1
        }
      } else {
          let s = [...iterableArray(it.spe)]
          const sIt = s.find((i) => i.id == id)
          const sIndex = s.findIndex((i) => i.id == id)

          if(sIt != undefined){
                  s = s.filter((i) => i.id != id)
                  arr = {
                    ...it,
                    spe: s
                }
            }
            let m = [...iterableArray(it.menus)]
            const mIt = m.find((i) => i.id == id)
            const mIndex = m.findIndex((i) => i.id == id)
            if(mIt != undefined){
                    m = m.filter((i) => i.id != id)
                    arr = {
                        ...it,
                        ...arr,
                        menus: m
                    }
                }
                arr = {
                    ...arr,
                    quantity: it.quantity - 1,
                }
      }
      
      qMenu.splice(index,1,arr)
      setQuantitiesMenu(qMenu)
    }
    reinit()
}
const reinit = () => {
    setForEdit(false)
    setProductId(null)
    setSpes(null)
    setMenuId(null)
    setQuantities([])
    setQuantitiesM([])
}


const getProduct = (id) => {
    const product = products.find((i) => i.id == id)
    return product
}

  const renderItemOptions = ({item}) => {
    return (
        <TouchableOpacity style={styles.row_main} onPress={() => {
          item?.onPress()
    }}>
        {/* BEGIN TO SETUP IMAGE AND TEXT VIEW */}
        <View style={{borderRadius: 20, width: 150, height: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: item.color, margin: 15}}>
            <View style={styles.image_container}>
                    {item.image}
                    {item.notification ? (
                        <View style={styles.notification_circle}>
                            <Text style={styles.notification}>3</Text>
                        </View>
                    ) : null}
                </View>
            {/* END TO SETUP IMAGE AND TEXT VIEW */}
            <Text style={[styles.item_text, {fontSize: 12}]}>{item.title}</Text>
        </View>
            
        </TouchableOpacity>
    );
}

const orientation = () => {
    if(Dimensions.get("window").height > Dimensions.get("window").width){
        return true
    }
    return false
}

const downButtonHandler = (index) => {
    scrollview_ref?.current?.scrollToIndex({
     animated: true,
     index,
     viewPosition: 0
   });
  };

   const onTicket = useCallback(() => {
    const session =  event?.sessions?.find((item) => item?.id == cart?.sessionId);
    navigation.navigate(ROUTES.PurchaseDetail, {
      event,
      session,
      isGenerate: true,
      cartId: cart.id,
      isPayed: true
    });
  }, [navigation, cart]);

  const onMessage = (event) => {
    var frame = document.getElementById('iframeId');
   
    if(event?.nativeEvent?.data?.length){
      // onGrouped(event.nativeEvent.data)
    }  if(event?.url?.indexOf('/success') > -1){
      onTicket()
    } else if (event?.url?.indexOf(`checkout/${cart.eventId}/${cart.sessionId}/${cart.id}/${accessToken}/${uid}`) == -1) {
      onBack()
    }
  }

  const onGrouped = useCallback((orderId) => {
    const session = event?.sessions?.find((item) => item?.id == cart?.sessionId);
    navigation.navigate(ROUTES.PurchaseDetail, {
      event,
      session,
      isGenerate: true,
      cartId: cart.id,
      isPayed: true,
      groupedPayment: true,
      orderId
    });
  }, [navigation, cart])

  const onNavigationStateChange = (event) => {
    if(event.url.indexOf('/success') > -1){
      onTicket()
    } else if (event.url.indexOf(`checkout-product/${cart.eventId}/${cart.sessionId}/${cart.id}/${accessToken}/${uid}`) == -1) {
      onBack()
    }
  }
  const onLoadProgress = (e) => {
    const event = e.nativeEvent;
   if(event.url.indexOf('/success') > -1){
      onTicket()
    } else if (event.url.indexOf(`checkout-product/${cart.eventId}/${cart.sessionId}/${cart.id}/${accessToken}/${uid}`) == -1) {
      onBack()
    }
  } 
  const createCart = async (option, notes) => {
    if(user){
      openForm(option, user, notes)
    } else {
      setOption(option)
      setForNumber(true)
    }
    

  }

  const onChangeCode = (text) => {
    text.trim()
    setCode(text.trim())
  }

  const openForm = async (op, user, not = notes) => {
      try {
        const id = Math.random().toString(36).slice(2)
        const order = {
            quantitiesMenu: quantitiesMenu.filter(i => i?.quantity > 0),
            quantitiesProduct: quantitiesProduct.filter(i => i?.quantity > 0),
            timestamp: new Date().getTime(),
            id,
            // @ts-ignore
            eventId: event.id,
            // @ts-ignore
            sessionId,
            uid: user?.uid,
            table: op === 'onTable' ? table : null,
            option: op,
            notes: not
        }
        await orderRef.doc(id).set(order)

        setCart(order)
    } catch (e) {
        console.log(e)
    }
  }

  const verifyPhone = (phone) => {
    const isValid = phoneInput?.current?.isValidNumber(phone)
    if(isValid){
      firebase.auth().signInWithPhoneNumber(phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        setForVerify(true)
        window.confirmationResult = confirmationResult;
      }).catch((error) => {
        console.log(error)
        setForVerify(false)
        showMessage({
          message: 'Une erreur est survenue. Vérifiez votre numéro de téléphone. Pour une meilleure prise en charge, évitez la navigation privée.',  
          icon: 'danger',
          type: 'danger'
        })
      });
    } else {
      showMessage({
        message: 'Veuillez entrer un numéro de téléphone valide.',  
        icon: 'danger',
        type: 'danger'
      })
    }
  
  }

  useEffect(() => {
    if(forNumber){
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
    }
  }, [forNumber])

  useEffect(() => {
    window.addEventListener('message', function(event) {
      
      // IMPORTANT: Check the origin of the data!
      if (event.origin.indexOf('https://wikmit.com') > -1 && event?.data?.isPaid) {
        setIsPaid(true)
        setOrderId(event?.data?.orderId)
        setCart({})
        fetch('https://us-central1-wikmit-c5cad.cloudfunctions.net/sendCodePhone', {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              "id": event?.data?.orderId,
              "phone": firebase.auth()?.currentUser?.phoneNumber
          })
      })
      const idCustomer = firebase.firestore().collection('customers').doc('id')

      firebase.database().ref('customers').orderByChild('groupId').equalTo(groupId || '').once('value', async snap => {
        let customers = []
        snap.forEach(row => {
          customers.push(row.val())
        })
        const customer = customers.find(item => item.userId == user.uid || (item?.email || 'a').toLowerCase().trim() == (mail || 'b').toLowerCase().trim())
        if(customer) {
          snap.forEach(child => {
            const item = child.val()
            if(item.userId == user.userId || (item?.email || 'a').toLowerCase().trim() == (mail || 'b').toLowerCase().trim() && !item?.userId && user?.uid){
              child.ref.update({
                userId: user.uid
              })
            }
          })
        } else {
          const increment = firebase.firestore.FieldValue.increment(1);
          await idCustomer.update({number: increment})
          const id = await idCustomer.get()
          const number = id.data().number 
          firebase.database().ref('events').orderByChild('id').equalTo(parseInt(eventId)).once('value', async snap => {
            let event = null
            snap.forEach(row => {
              event = row.val()
            })
            firebase.database().ref('customers').push({
              name: `${name}`,
              address: '',
              id: number,
              groupId,
              email: mail,
              phone: user.phoneNumber,
              ...(user?.uid ? {userId: user?.uid} : {})
            })
            orderRef.doc(event?.data?.orderId).update({customerId: number})
          })
          
        }
      })
      } else if (event.origin.indexOf('https://wikmit.com') > -1 && event?.data?.isGrouped) {
        setIsGrouped(true)
        setOrderId(event?.data?.orderId)
        setCart({})
      } else {
          // The data hasn't been sent from your site!
          // Be careful! Do not use it.
          return;
      }
  });
  },[])


  if(loading){
    return ( 
     <View>
         <View style={{height: ThemeUtils.APPBAR_HEIGHT,
         alignItems: 'center',
         justifyContent: 'center',
         }}>
         <Header
           scrollY={scrollY}
           title={"Restaurant"}
         />
         <Image 
         source={require('../../img/logo-dark.png')} 
         style={{height: 30, width:120}}/>
         </View>
     </View>
     )
   }
  if(!exist){
 return ( 
  <View>
      <View style={{height: ThemeUtils.APPBAR_HEIGHT,
      alignItems: 'center',
      justifyContent: 'center',
      }}>
      <Header
        scrollY={scrollY}
        title={"Restaurant"}
      />
      <Image 
      source={require('../../img/logo-dark.png')} 
      style={{height: 30, width:120}}/>
      </View>
      <View style={{padding: 40, alignItems: 'center', justifyContent: 'center'}}>
      <Text style={{fontSize: 20, margin: 20, textAlign: 'center'}}>Désolé ! Cette page n'est pas ou n'est plus disponible.</Text>
      </View>
  </View>
  )
}

  if(!isOpen && exist){
    return (
      <View>
          <View style={{height: ThemeUtils.APPBAR_HEIGHT,
          alignItems: 'center',
          justifyContent: 'center',
          }}>
          <Header
            scrollY={scrollY}
            title={"Restaurant"}
          />
          <Image 
          source={require('../../img/logo-dark.png')} 
          style={{height: 30, width:120}}/>
          </View>
          <View style={{padding: 40, alignItems: 'center', justifyContent: 'center'}}>
          <Image style={{height: 200, width: 120}} source={{uri: event?.poster?.url}} />

          <Text style={{fontSize: 20, margin: 20, textAlign: 'center'}}>Le service de restauration de {event?.fields?.e_title} est fermé !</Text>
          <Text style={{fontSize: 20, textAlign: 'center'}}>Revenez plus tard !</Text>
          </View>
      </View>
    )
  }

  if(isPaid && orderId){
    return(
      <View style={{flex:1, alignItems: 'center', flexDirection: 'column', backgroundColor: 'white'}}>
        <View style={{padding: 15, alignItems: 'center'}}>
        <img src={require("../../../assets/verified.gif")} style={{width: 100, height: 100, marginBottom: 20}}/>
        <Text style={{fontSize: 20, marginBottom: 20}}>Merci pour votre commande !</Text>
        <Text style={{fontSize: 15, marginBottom: 10}}>Elle sera prête dans un instant.</Text>
        <Text style={{fontSize: 15, marginBottom: 20}}>Présentez le code ci-dessous le moment venu:</Text>
        <View>
          <Text style={{fontSize: 25, marginBottom: 10, fontWeight: 'bold'}}>{orderId?.toUpperCase()}</Text>
        </View>
        </View>
      </View>
    )
  }  
  if(isGrouped && orderId){
    return(
      <View style={{flex:1, alignItems: 'center', flexDirection: 'column', backgroundColor: 'white'}}>
        <View style={{padding: 15, alignItems: 'center'}}>
        <img src={require("../../../assets/verified.gif")} style={{width: 100, height: 100, marginBottom: 20}}/>
        <Text style={{fontSize: 20, marginBottom: 20}}>Merci pour votre paiement !</Text>
        <Text style={{fontSize: 15, marginBottom: 20}}>Partagez le code ci-dessous à un utilisateur de l'application Wikmit:</Text>
        <View>
          <Text style={{fontSize: 25, marginBottom: 10, fontWeight: 'bold'}}>{orderId?.toUpperCase()}</Text>
        </View>
        </View>
      </View>
    )
  } 

if(openCode){
    return (
        <View style={styles.billContainer}>
          <View>
            <Text style={{color: colors.text}}>
              Entrez le code de votre table
            </Text>
            <TextInput autoCapitalize='none' value={code} onChangeText={onChangeCode} style={styles.textInput}>
  
            </TextInput>
            <TouchableOpacity onPress={() => {
                let c = code.replace('#', '')
                if(!isNaN(c)){
                    c = parseInt(c)
                }
                if(session?.tables && session?.tables?.map(i => i.id)?.includes(c)){
                    setOption('onTable')
                    setOpenOptionScreen(false)
                    setOpenCode(false)
                    setTable(c)
                    createCart('onTable')
                } else {
                    showMessage({
                        message: 'Le code entré ne correspond à aucune table',
                        type: "danger",
                    })
                }
            }} style={[styles.button,{borderColor:'#008379', marginTop: 50, borderWidth: 1, alignItems:'center', padding: 20}]}>
              <Text style={[styles.buttonText]}>Valider</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setOpenCode(false)} style={[styles.button,{borderColor:'#008379', marginTop: 50, borderWidth: 1, alignItems:'center', padding: 20}]}>
              <Text style={[styles.buttonText]}>Retour</Text>
            </TouchableOpacity>
          </View>
        </View>
      )
}
if((typeof sessionId == 'number' && typeof eventId == 'number' && openOptionScreen) ){
    return (
      <View style={[styles.container, {
        position: 'relative'
      }]}>
           <View style={{height: ThemeUtils.APPBAR_HEIGHT,
         alignItems: 'center',
         justifyContent: 'center',
         }}>
        <Header
          scrollY={scrollY}
          title={"Restaurant"}
        />
        <Image 
        source={require('../../img/logo-dark.png')} 
        style={{height: 30, width:120}}/>
        </View>
         <ScrollView 
        onScroll={Animated.event([
          {
            nativeEvent: {contentOffset: {y: scrollY}},
          },
        ])}
        >
            <View style={{marginTop: 30, marginLeft: 30}}>
                  <Text style={{fontSize: 30, color: "#000"}}>Service à table</Text>
            </View>
            <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
              <FlatList
                numColumns={2}
                data={[
                {title: "Entrer le code", image: <SvgHashtag />, color: "#95afc0", onPress: () => {
                    setOpenCode(true)
                }},
                {title: "Récupérer au comptoir", image: <SvgCounter />, color: "#0a3d62", onPress: () => {
                    setOption("onBar")
                    setOpenOptionScreen(false)
                    createCart("onBar")
                }}]}
                renderItem={renderItemOptions}
                keyExtractor={keyExtractor}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{marginTop: orientation() ? 0 : 100, alignItems: "center"}}
                />          
            </View>
        </ScrollView>
      </View>
       
          
      )
}
if(forNumber){
  return(
    <View style={styles.billContainer}>
          <View>
          <Text style={{color: colors.text, margin: 5}}>
              Nom complet
            </Text>
            <TextInput autoCapitalize='none' value={name} onChangeText={setName} style={[styles.textInput, {margin: 5}]}>
  
            </TextInput>
            <Text style={{color: colors.text, margin: 5}}>
              Adresse mail
            </Text>
            <TextInput autoCapitalize='none' value={mail} onChangeText={setMail} style={[styles.textInput, {margin: 5}]}>
  
            </TextInput>
            <Text style={{color: colors.text, margin: 5}}>
              Numéro de téléphone
            </Text>
            {/* <TextInput autoCapitalize='none' value={phone} onChangeText={setPhone} style={[styles.textInput, {margin: 5}]}>
  
            </TextInput> */}
             <PhoneInput
            initialCountry={'fr'}
            ref={phoneInput}
            style={[styles.textInput, {margin: 5}]}
            value={phone}
            onSelectCountry={(val) =>
            getCountry(val)
            }
            onChangePhoneNumber={(val) =>{
              setPhone(val)
              }}
            confirmText={'Confirmer'}
            cancelText={'Annuler'}
            />
            {
              forVerify ?
              <>
                <Text style={{color: colors.text, margin: 5}}>
                  Code de vérification
                </Text>
                <TextInput autoCapitalize='none' value={verifyCode} onChangeText={setVerifyCode} style={[styles.textInput, {margin: 5}]}>
      
                </TextInput>
              </>
              : null
            }
            {
              !forVerify ?
              <div id="recaptcha-container"></div>   
              : null
            }
            <TouchableOpacity onPress={() => {
              if(!name?.trim()){
                showMessage({
                  message: 'Votre nom complet doit être renseigné',  
                    icon: 'danger',
                    type: 'danger'
                })
              } else if(!mail?.trim()){
                showMessage({
                  message: 'Votre adresse mail doit être renseigné',  
                    icon: 'danger',
                    type: 'danger'
                })
              } else if(forVerify){
                var credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verifyCode);

                firebase.auth().signInWithCredential(credential).then((result) => {

                  setUser(result.user)
                  setForNumber(false)
                  openForm(option, result.user)
                  showMessage({
                    message: 'Votre numéro a bien été vérifié',  
                    icon: 'success',
                    type: 'success'
                  })
                }).catch((error) => {
                  showMessage({
                    message: 'Le code entré est incorrect.',  
                    icon: 'danger',
                    type: 'danger'
                  })
                });
              } else {
                verifyPhone(phone)
              }
                
            }} style={[styles.button, {margin: 5}]}>
              <Text style={[styles.buttonText]}>Valider</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setForNumber(false)} style={[styles.button, {margin: 5}]}>
              <Text style={[styles.buttonText]}>Retour</Text>
            </TouchableOpacity>
          </View>

        </View>
  )
}
 if(accessToken && uid && Url.wikmit && typeof cart?.eventId == 'number' && typeof cart?.sessionId == 'number' && cart.id){
    return (        
          <KeyboardAvoidingView style={{flex: 1}}>
          <TouchableOpacity 
          onPress={() => {
              setCart({})
            }}
          style={{alignItems: 'flex-end', paddingTop: 20, paddingRight: 10, paddingBottom:20}}>
              <Text style={{fontWeight: 'bold', fontSize: 20, color: "#008379"}}>Retour</Text>
          </TouchableOpacity>
          <iframe src={`${Url.wikmit}checkout-product/${cart.eventId}/${cart.sessionId}/${cart.id}/${accessToken}/${uid}?rest=true`} height={'100%'} width={'100%'} />
            {/* <WebView 
            id={'iframeId'}
            onMessage={onMessage} 
            onLoadProgress={onLoadProgress} onNavigationStateChange={onNavigationStateChange} 
            source={{uri: `${Url.wikmit}checkout-product/${cart.eventId}/${cart.sessionId}/${cart.id}/${accessToken}/${uid}`}} /> */}
        </KeyboardAvoidingView>      
    );
  }

  if(showCart){
      const total = getTotal()
    return (
        <CartScreen 
        theme={theme}
        total={total}
        menus={menus}
        products={products}
        event={event}
        addMenu={addQuantitiesMenu}
        editMenu={editQuantitiesMenu}
        removeMenu={removeQuantitiesMenu}
        addProduct={addQuantities}
        editProduct={editQuantities}
        removeProduct={removeQuantities}
        quantities={quantitiesProduct}
        quantitiesMenu={quantitiesMenu}
        hideCart={() => setShowCart(false)}
        goToPay={(notes) => {
            setNotes(notes)
            if(table && session?.tables && session?.tables?.map(i => i.id)?.includes(table)){
              setOption('onTable')
              createCart('onTable', notes)
              
            } else if(restaurant?.onTable && session?.tables?.length){
                setOpenOptionScreen(true)
            } else {
                createCart('onBar', notes)
            }
        }}
        />
    )
  }
  if(typeof menuId == "number"){
    const menu = menus.find((i ) => i.id == menuId)
    const quantity = quantitiesMenu.find((i ) => i.id == menuId)
    const menuItems = menu?.menu?.menuItems ? menu?.menu?.menuItems : []
    const options = menu?.menu?.options ? menu?.menu?.options : []
    return (
      <View style={[styles.container, {
        position: 'relative'
      }]}>
           <View style={{height: ThemeUtils.APPBAR_HEIGHT,
         alignItems: 'center',
         justifyContent: 'center',
         }}>
        <Header
          scrollY={scrollY}
          title={"Restaurant"}
        />
        <Image 
        source={require('../../img/logo-dark.png')} 
        style={{height: 30, width:120}}/>
        </View>

        <ScrollView 
        onScroll={Animated.event([
          {
            nativeEvent: {contentOffset: {y: scrollY}},
          },
        ])}
        style={[styles.container, {backgroundColor: colors.background}]}>
        <View>
          {/* {
            this.state.error &&
            <View style={{ alignItems: 'center', marginTop:20, padding:10 }}>
              <Text style={{ color: 'red' }}>
                {this.state.error}
              </Text>
            </View>
          } */}
          <TouchableOpacity 
          onPress={() => {
              setForEdit(false)
              setProductId(null)
              setSpes(null)
              setMenuId(null)
              setQuantities([])
              setQuantitiesM([])
            }}
          style={{alignItems: 'flex-end', paddingTop: 20, paddingRight: 10, paddingBottom:20, backgroundColor: '#fff'}}>
              <Text style={{fontWeight: 'bold', fontSize: 20, color: "#008379"}}>Retour</Text>
          </TouchableOpacity>
          
          
          <View
        style={[styles.itemProduct, {backgroundColor: colors.backgroundColor}]}>
          {menu?.menu?.image?.url ? (
            <View style={styles.viewImage}>
              <Image style={styles.cardImage} source={{uri: menu?.menu.image.url}} />
            </View>
          ) : null}
        <View style={[styles.column, (!menu?.menu?.image?.url ? {alignItems: 'center', width} : {})]}>
          <Text
            numberOfLines={2}
            style={[styles.product, {color: colors.text}]}>
            {menu?.menu.p_name}
          </Text>
          <Text style={[styles.description, {color: '#666'}]}>
            {menu?.menu?.isUnlimited
              ? 'Illimité'
              : typeof menu?.menu?.p_realAvailable == 'number' &&
              menu?.menu?.p_realAvailable > 0 &&
              menu?.menu?.p_realAvailable <= 20
              ? `Plus que ${menu?.menu?.p_realAvailable} disponible(s)`
              : menu?.menu?.p_available <= 20
              ? `Plus que ${menu?.menu?.p_available} disponible(s)`
              : ''}
          </Text>
          {menu?.menu?.p_description?.length > 200 ? (
            <Text style={styles.description}></Text>
          ) : (
            <Text style={styles.description}>{menu?.menu?.p_description}</Text>
          )}
          <Text style={[styles.price, {color: colors.text}]}>
            {menu?.menu?.isFree
              ? 'Gratuit'
              : `${menu?.menu?.p_price.toFixed(2).replace('.', ',')}€`}
          </Text>
        </View>
        <View/>
      </View>


          {forEdit &&
          <ScrollView 
          contentContainerStyle={{ padding:10, marginTop: 20 }}
          horizontal
          showsHorizontalScrollIndicator={false}
          >
          { Array.from(Array(quantity?.quantity ? quantity?.quantity : 0).keys()).length > 0 ?
          Array.from(Array(quantity?.quantity).keys()).map((item,index) => {
            const quantitySpeLength = quantity?.spe?.length ? quantity?.spe?.length : 0
            const id = quantitySpeLength > index ? quantity.spe[index].id : index
            const quantityMenuLength = quantity?.menus?.length ? quantity?.menus?.length : 0
            const idM = quantityMenuLength > index ? quantity.menus[index].id : index
              return ( 
              <View style={{margin: 5}}>
                  <SpeRadio 
                  size={25}
                  isCheck={spes == id}
                  borderColor={'#7F8FA6'}
                  checkedColor={'#008379'}
                  sign={item+1}
                  key={index}
                  onPress={() => {
                      setSpes(id)
                      if(id >= quantitySpeLength && quantity?.spe?.length > 0){
                        setQuantities(iterableArray(quantity?.spe.find((i) => i?.id == id)?.spe))
                    }
                    if(idM >= quantityMenuLength && quantity?.menus?.length > 0){
                        setQuantitiesM(iterableArray(quantity?.menus.find((i) => i?.id == idM)?.menu))
                    }
                  }}
                  />
                  
              </View>)
          })
          : null
          }
          </ScrollView>
          }
          
          
          { menu?.menu?.menuItems?.length > 0 ?
            <>
            {
                menu?.menu?.menuItems?.map((it , index) => 
                    <>
                    {Array.from(Array(it?.quantity ? it?.quantity : 0).keys()).map(item3 => {
                        const title = it?.name ? it?.name : ''
                        const product = getProduct(it?.productId)
                        const ingredients = product?.product?.ingredients
                        return (
                               <>
                               {
                                   ingredients && ingredients?.find((it) => it.add || (it.isInitial && it.remove)) &&
                                    <View style={styles.header}>
                                        <Text style={styles.textHeader}>
                                            {title} {it?.quantity > 1 ? `n°${item3+1}` : null}
                                        </Text>
                                    </View>
                               }
                               
                                {
                                    ingredients && ingredients?.find((it) => it.add) ?
                                    <View style={styles.view}>
                                        <View style={[styles.header, {backgroundColor: dark ? "#34495e": "#f8f9fa"}]}>
                                            <Text style={{color: colors?.text}}>
                                                    Ajouter des composants
                                            </Text>
                                        </View>
                                        
                                        
                                        { 
                                                ingredients?.filter((i) => i?.add).map((i, ind) =>
                                                <TouchableOpacity 
                                                key={ind} 
                                                style={[styles.item, {backgroundColor: colors.background}]}
                                                onPress={() => {
                                                    if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'plus' && item2.index == `${index}-${item3}-${ind}`})){
                                                        setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'plus' || item2.index != `${index}-${item3}-${ind}`))
                                                    } else {
                                                        setQuantities([...iterableArray(quantities), {id: i.id, itemId: it.id, sign: 'plus', name: i.name, isFree: i?.isFreePlus ? true : false, costPrice: i?.p_costPrice_plus ? parseFloat(i?.p_costPrice_plus) : 0, price: i?.p_price_plus ? parseFloat(i?.p_price_plus) : 0, productId: product.id, isMenuList: true, index: `${index}-${item3}-${ind}`}])
                                                    }
                                                }}>
                                                    <Text style={[styles.textItem, {color: colors.text}]}>
                                                    {i.name} {!i?.isFreePlus && i?.p_price_plus && typeof i?.p_price_plus == "number" ? `(+${i?.p_price_plus.toFixed(2).replace('.', ',')}€)` : !i?.isFreePlus && i?.p_price_plus ? `${'(+'+i?.p_price_plus?.replace('.', ",")+"€)"}` : null}
                                                    </Text>
                                                    <SpeRadio
                                                        onPress={() => {
                                                            if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'plus' && item2.index == `${index}-${item3}-${ind}`})){
                                                                setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'plus' || item2.index != `${index}-${item3}-${ind}`))
                                                            } else {
                                                                setQuantities([...iterableArray(quantities), {id: i.id, itemId: it.id, sign: 'plus', name: i.name, isFree: i?.isFreePlus ? true : false, costPrice: i?.p_costPrice_plus ? parseFloat(i?.p_costPrice_plus) : 0, price: i?.p_price_plus ? parseFloat(i?.p_price_plus) : 0, productId: product.id, isMenuList: true, index: `${index}-${item3}-${ind}`}])
                                                            }
                                                        }}
                                                        isCheck={quantities.find((item2) => item2.id == i.id && item2.sign == 'plus'  && item2.index == `${index}-${item3}-${ind}`) ? true : false}
                                                        borderColor={'#7F8FA6'}
                                                        checkedColor={'#008379'}
                                                        sign={'+'}
                                                    />
                                                </TouchableOpacity>
                                                )
                                            }
                                    </View>
                                        : null
                                }
                                {
                                    ingredients && ingredients?.find((it) => (it?.remove && it?.isInitial)) &&
                                    <View style={styles.view}>
                                        <View style={[styles.header, {backgroundColor: dark ? "#34495e": "#f8f9fa"}]}>
                                            <Text style={{color: colors.text}}>
                                                    Retirer des composants
                                            </Text>
                                        </View>
                                        <View>
                                            { 
                                                ingredients.filter((i) => (i?.remove && i?.isInitial)).map((i, ind) =>
                                                <TouchableOpacity 
                                                key={ind} 
                                                style={[styles.item, {backgroundColor: colors.background}]}
                                                onPress={() => {
                                                    if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'minus' && item2.index == `${index}-${item3}-${ind}`})){
                                                        setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'minus' || item2.index != `${index}-${item3}-${ind}`))
                                                    } else {
                                                        setQuantities([...iterableArray(quantities), {id: i.id, itemId: it.id, sign: 'minus', name: i.name, isFree: i?.isFreeMinus ? true : false, costPrice: i?.p_costPrice_minus ? i?.p_costPrice_minus : 0, price: i?.p_price_minus ? i?.p_price_minus : 0, productId: product.id, isMenuList: true, index: `${index}-${item3}-${ind}`}])
                                                    }
                                                }}>
                                                    <Text style={[styles.textItem, {color: colors.text}]}>
                                                    {i.name} {!i?.isFreeMinus && i?.p_price_minus && typeof i?.p_price_minus == "number" ? `(${i?.p_price_minus.toFixed(2).replace('.', ',')}€)` : !i?.isFreeMinus && i?.p_price_minus ? `${'('+i?.p_price_minus?.replace('.', ",")+"€)"}` : null}
                                                    </Text>
                                                    <SpeRadio
                                                    onPress={() => {
                                                            if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'minus' && item2.index == `${index}-${item3}-${ind}`})){
                                                                setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'minus' || item2.index != `${index}-${item3}-${ind}`))
                                                            } else {
                                                                setQuantities([...iterableArray(quantities), {id: i.id, itemId: it.id, sign: 'minus', name: i.name, isFree: i?.isFreeMinus ? true : false, costPrice: i?.p_costPrice_minus ? i?.p_costPrice_minus : 0, price: i?.p_price_minus ? i?.p_price_minus : 0, productId: product.id, isMenuList: true, index: `${index}-${item3}-${ind}`}])
                                                            }
                                                        }}
                                                        isCheck={quantities.find((item2) => item2.id == i.id && item2.sign == 'minus' && item2.index == `${index}-${item3}-${ind}`) ? true : false}
                                                        borderColor={'#7F8FA6'}
                                                        checkedColor={'#008379'}
                                                        sign={'-'}
                                                    />
                                                </TouchableOpacity>
                                                )
                                            }
                                        </View>
                                        </View>
                                }

                                {
                                    options && options?.filter((i) => i?.related?.id == product?.id && !i.forProduct && i?.listItems?.length > 0).map((it2, index2) =>
                                    <View style={styles.view}>
                                        <View style={[styles.header, {backgroundColor: dark ? "#34495e": "#f8f9fa"}]}>
                                            <Text style={{color: colors?.text}}>
                                                {it2.nameList}
                                            </Text>
                                            <Text style={{color: dark ? "#fff" : "#555", marginTop: 3}}>
                                                {it2?.type == 2 ? `${it2.min} choix obligatoire${it2?.min > 1 ? "s" : ""}` : it2?.type == 3 ? "Obligatoire" : null}
                                            </Text>
                                        </View>
                                        <View>
                                            { 
                                                it2?.listItems.map((i, ind) =>
                                                <TouchableOpacity 
                                                key={ind} 
                                                style={[styles.item, {backgroundColor: colors.background}]}
                                                onPress={() => {
                                                    if(it2?.type == 3){
                                                        setQuantitiesM([...iterableArray(quantitiesM).filter((item2) => !item2?.optionsItem || !item2.index.includes(`${index}-${item3}-${index2}-`)), {id: i.item.id, type: it2?.type, itemId: it.id, optionId: it2.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: product.id, optionsItem: true, index: `${index}-${item3}-${index2}-${ind}`}])
                                                    } else {
                                                        if(quantitiesM.find((item2) => {return item2.id == i.item.id && item2.optionsItem && item2.index == `${index}-${item3}-${index2}-${ind}`})){
                                                            setQuantitiesM(quantitiesM.filter((item2) => item2.id != i.item.id || !item2.optionsItem || item2.index != `${index}-${item3}-${index2}-${ind}`))
                                                        } else {
                                                        setQuantitiesM([...iterableArray(quantitiesM), {id: i.item.id, type: it2?.type, itemId: it.id, optionId: it2.id,name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: product.id, optionsItem: true, index: `${index}-${item3}-${index2}-${ind}`}])
                                                        }
                                                    }
                                                }}>
                                                    <Text style={[styles.textItem, {color: colors.text}]}>
                                                    {i.item.type} {!i?.isFree && i?.p_price && typeof i?.p_price == "number" ? `(+${i?.p_price.toFixed(2).replace('.', ',')}€)` : !i?.isFree && i?.p_price ? `${'(+'+i?.p_price?.replace('.', ",")+"€)"}` : null}
                                                    </Text>
                                                    {
                                                        it2?.type == 1 ?
                                                        <CheckBox
                                                        onPress={() => {
                                                            if(quantitiesM.find((item2) => {return item2.id == i.item.id && item2.optionsItem && item2.index == `${index}-${item3}-${index2}-${ind}`})){
                                                                setQuantitiesM(quantitiesM.filter((item2) => item2.id != i.item.id || !item2.optionsItem || item2.index != `${index}-${item3}-${index2}-${ind}`))
                                                            } else {
                                                                setQuantitiesM([...iterableArray(quantitiesM), {id: i.item.id, type: it2?.type, itemId: it.id, optionId: it2.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: product.id, optionsItem: true, index: `${index}-${item3}-${index2}-${ind}`}])
                                                            }
                                                        }}
                                                            isCheck={quantitiesM.find((item2) => item2.id == i.item.id && item2.optionsItem && item2.index == `${index}-${item3}-${index2}-${ind}`) ? true : false}
                                                            borderColor={'#7F8FA6'}
                                                            checkedColor={'#008379'}
                                                        />
                                                        : it2?.type == 2 ?
                                                        <CheckBox
                                                        onPress={() => {
                                                            if(quantitiesM.find((item2) => {return item2.id == i.item.id && item2.optionsItem && item2.index == `${index}-${item3}-${index2}-${ind}`})){
                                                                setQuantitiesM(quantitiesM.filter((item2) => item2.id != i.item.id || !item2.optionsItem || item2.index != `${index}-${item3}-${index2}-${ind}`))
                                                            } else {
                                                                setQuantitiesM([...iterableArray(quantitiesM), {id: i.item.id, type: it2?.type, itemId: it.id, optionId: it2.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: product.id, optionsItem: true, index: `${index}-${item3}-${index2}-${ind}`}])
                                                            }
                                                        }}
                                                        isCheck={quantitiesM.find((item2) => item2.id == i.item.id && item2.optionsItem && item2.index == `${index}-${item3}-${index2}-${ind}`) ? true : false}
                                                        borderColor={'#7F8FA6'}
                                                            checkedColor={'#008379'}
                                                        />
                                                        : 
                                                        <Radio
                                                        onPress={() => {
                                                            setQuantitiesM([...iterableArray(quantitiesM).filter((item2) => !item2?.optionsItem || !item2.index.includes(`${index}-${item3}-${index2}-`)), {id: i.item.id, type: it2?.type, itemId: it.id, optionId: it2.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: product.id, optionsItem: true, index: `${index}-${item3}-${index2}-${ind}`}])
                                                        }}
                                                        isCheck={quantitiesM.find((item2) => item2.id == i.item.id && item2.optionsItem && item2.index == `${index}-${item3}-${index2}-${ind}`) ? true : false}
                                                        borderColor={'#7F8FA6'}
                                                            checkedColor={'#008379'}
                                                        />
                                                    }
                                                    
                                                </TouchableOpacity>
                                                )
                                            }
                                        </View>
                                    </View>
                                    )                                    
                                }
                                </>
                        )
                        })}
                    </>
                )
            }
            </>
            : null
           }
           {
               menu?.menu?.options?.find((it) => it?.forProduct) &&
                <>
                {
                    menu?.menu?.options?.filter((i) => i.forProduct && i?.listItems?.length > 0)?.map((it , index) =>{
                        const title = it.nameList
                        return ( 
                           
                            <View style={styles.view}>
                            <View style={[styles.header, {backgroundColor: dark ? "#34495e": "#f8f9fa"}]}>
                                <Text style={{color: colors?.text}}>
                                    {it.nameList}
                                </Text>
                                <Text style={{color: dark ? "#fff" : "#555", marginTop: 3}}>
                                    {it?.type == 2 ? `${it.min} choix obligatoire${it?.min > 1 ? "s" : ""}` : it?.type == 3 ? "Obligatoire" : null}
                                </Text>
                            </View>
                            <View>
                                { 
                                    it?.listItems.filter((i) => {
                                        const product = products?.find((ite) => {
                                          return i?.item?.productId == ite.id
                                        })
                                        return product?.product?.isUnlimited || (typeof product?.product?.p_realAvailable == 'number' && product?.product?.p_realAvailable > 0) || (typeof product?.product?.p_realAvailable != 'number' && product?.product?.p_available > 0)  
                                      }).map((i, ind) =>
                                    <TouchableOpacity 
                                    key={ind} 
                                    style={[styles.item, {backgroundColor: colors.background}]}
                                    onPress={() => {
                                        if(it?.type == 3){
                                            setQuantitiesM([...iterableArray(quantitiesM).filter((item2) => !item2?.optionsProduct || !item2.index.includes(`${index}-`)), {id: i.item.id,type: it?.type, optionId: it.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: i.item.productId, optionsProduct: true, index: `${index}-${ind}`}])
                                        } else {
                                            if(quantitiesM.find((item2) => {return item2.id == i.item.id && item2.optionsProduct && item2.index == `${index}-${ind}`})){
                                                setQuantitiesM(quantitiesM.filter((item2) => item2.id != i.item.id || !item2.optionsProduct || item2.index != `${index}-${ind}`))
                                            } else {
                                            setQuantitiesM([...iterableArray(quantitiesM), {id: i.item.id, type: it?.type, optionId: it.id,name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: i.item.productId, optionsProduct: true, index: `${index}-${ind}`}])
                                            }
                                        }
                                    }}>
                                        <Text style={[styles.textItem, {color: colors.text}]}>
                                        {i.item.type} {!i?.isFree && i?.p_price && typeof i?.p_price == "number" ? `(+${i?.p_price.toFixed(2).replace('.', ',')}€)` : !i?.isFree && i?.p_price ? `${'(+'+i?.p_price?.replace('.', ",")+"€)"}` : null}
                                        </Text>
                                        {
                                            it?.type == 1 ?
                                            <CheckBox
                                            onPress={() => {
                                                if(quantitiesM.find((item2) => {return item2.id == i.item.id && item2.optionsProduct && item2.index == `${index}-${ind}`})){
                                                    setQuantitiesM(quantitiesM.filter((item2) => item2.id != i.item.id || !item2.optionsProduct || item2.index != `${index}-${ind}`))
                                                } else {
                                                    setQuantitiesM([...iterableArray(quantitiesM), {id: i.item.id, type: it?.type, optionId: it.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: i.item.productId, optionsProduct: true, index: `${index}-${ind}`}])
                                                }
                                            }}
                                                isCheck={quantitiesM.find((item2) => item2.id == i.item.id && item2.optionsProduct && item2.index == `${index}-${ind}`) ? true : false}
                                                borderColor={'#7F8FA6'}
                                                checkedColor={'#008379'}
                                            />
                                            : it?.type == 2 ?
                                            <CheckBox
                                            onPress={() => {
                                                if(quantitiesM.find((item2) => {return item2.id == i.item.id && item2.optionsProduct && item2.index == `${index}-${ind}`})){
                                                    setQuantitiesM(quantitiesM.filter((item2) => item2.id != i.item.id || !item2.optionsProduct || item2.index != `${index}-${ind}`))
                                                } else {
                                                    setQuantitiesM([...iterableArray(quantitiesM), {id: i.item.id, type: it?.type, optionId: it.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: i.item.productId, optionsProduct: true, index: `${index}-${ind}`}])
                                                }
                                            }}
                                            isCheck={quantitiesM.find((item2) => item2.id == i.item.id && item2.optionsProduct && item2.index == `${index}-${ind}`) ? true : false}
                                            borderColor={'#7F8FA6'}
                                                checkedColor={'#008379'}
                                            />
                                            : 
                                            <Radio
                                            onPress={() => {
                                                setQuantitiesM([...iterableArray(quantitiesM).filter((item2) => !item2?.optionsProduct || !item2.index.includes(`${index}-`)), {id: i.item.id, type: it?.type, optionId: it.id, name: i.item.type, isFree: i?.isFree ? true : false, costPrice: i?.p_costPrice ? i?.p_costPrice : 0, price: i?.p_price ? i?.p_price : 0, productId: i.item.productId, optionsProduct: true, index: `${index}-${ind}`}])
                                            }}
                                            isCheck={quantitiesM.find((item2) => item2.id == i.item.id && item2.optionsProduct && item2.index == `${index}-${ind}`) ? true : false}
                                            borderColor={'#7F8FA6'}
                                                checkedColor={'#008379'}
                                            />
                                        }
                                        
                                    </TouchableOpacity>
                                    )
                                }
                            </View>
                        </View>
                                  
                            )
                    }
                    
                    )
                }
                </>
           }
           
        </View>
        
          <View style={styles.buttonSection}>
            <TouchableOpacity 
            onPress={() => {
                if(forEdit){
                    editQuantitiesMenu(quantity?.spe?.length > spes ? null : spes)
                } else {
                    addQuantitiesMenu(menuId)
                }
            }} 
            style={styles.button}>
              <Text style={styles.buttonText}>
                 {forEdit ? "Modifier " : "Ajouter "}le menu
              </Text>
            </TouchableOpacity>
          </View>
          {
              forEdit ? 
              <View style={styles.buttonSection}>
                    <TouchableOpacity 
                    onPress={() => {
                        removeQuantitiesMenu(quantity?.spe?.length > spes ? null : spes)
                    }} 
                    style={styles.buttonRemove}>
                    <Text style={[styles.buttonText, {color: '#ee5253'}]}>
                        Supprimer le menu
                    </Text>
                    </TouchableOpacity>
                </View>
                : null
          }
        </ScrollView>
      </View>
      );
  }
  if(typeof productId == "number"){
    const product = products.find((i ) => i.id == productId)
    const quantity = quantitiesProduct.find((i ) => i.id == productId)
    const ingredients = product?.product?.ingredients
    return (
      <View style={[styles.container, {
        position: 'relative'
      }]}>
           <View style={{height: ThemeUtils.APPBAR_HEIGHT,
         alignItems: 'center',
         justifyContent: 'center',
         }}>
        <Header
          scrollY={scrollY}
          title={"Restaurant"}
        />
        <Image 
        source={require('../../img/logo-dark.png')} 
        style={{height: 30, width:120}}/>
        </View>

        <ScrollView 
        onScroll={Animated.event([
          {
            nativeEvent: {contentOffset: {y: scrollY}},
          },
        ])}
        style={[styles.subContainer, {backgroundColor: colors.background, paddingTop:0}]}>
        <View>
          <TouchableOpacity 
          onPress={() => {
              setForEdit(false)
              setProductId(null)
              setSpes(null)
              setMenuId(null)
              setQuantities([])
              setQuantitiesM([])
            }}
          style={{alignItems: 'flex-end', paddingTop: 20, paddingRight: 10, backgroundColor: '#fff'}}>
              <Text style={{fontWeight: 'bold', fontSize: 20, color: "#008379"}}>Retour</Text>
          </TouchableOpacity>


          <View
        style={[styles.itemProduct, {backgroundColor: colors.backgroundColor}]}>
          {product?.product?.image?.url ? (
            <View style={styles.viewImage}>
              <Image style={styles.cardImage} source={{uri: product?.product.image.url}} />
            </View>
          ) :  null
          }
        <View style={[styles.column, (!product?.product?.image?.url ? {alignItems: 'center', width} : {})]}>
          <Text
            numberOfLines={2}
            style={[styles.product, {color: colors.text}]}>
            {product?.product.p_name}
          </Text>
          <Text style={[styles.description, {color: '#666'}]}>
            {product?.product?.isUnlimited
              ? 'Illimité'
              : typeof product?.product?.p_realAvailable == 'number' &&
              product?.product?.p_realAvailable > 0 &&
              product?.product?.p_realAvailable <= 20
              ? `Plus que ${product?.product?.p_realAvailable} disponible(s)`
              : product?.product?.p_available <= 20
              ? `Plus que ${product?.product?.p_available} disponible(s)`
              : ''}
          </Text>
          {product?.product?.p_description?.length > 200 ? (
            <Text style={styles.description}></Text>
          ) : (
            <Text style={styles.description}>{product?.product?.p_description}</Text>
          )}
          <Text style={[styles.price, {color: colors.text}]}>
            {product?.product?.isFree
              ? 'Gratuit'
              : `${product?.product?.p_price.toFixed(2).replace('.', ',')}€`}
          </Text>
        </View>
        <View/>
      </View>

          {forEdit &&
          <ScrollView 
          contentContainerStyle={{ padding:10, marginTop: 20 }}
          horizontal
          showsHorizontalScrollIndicator={false}
          >
          { Array.from(Array(quantity?.quantity ? quantity?.quantity : 0).keys()).length > 0 ?
          Array.from(Array(quantity?.quantity).keys()).map((item,index) => {
             const quantitySpeLength = quantity?.spe?.length ? quantity?.spe?.length : 0
             const id = quantitySpeLength > index ? quantity.spe[index].id : index
              return ( 
              <View style={{margin: 5}}>
                  <SpeRadio 
                  size={25}
                  isCheck={spes == id}
                  borderColor={'#7F8FA6'}
                  checkedColor={'#008379'}
                  sign={item+1}
                  key={index}
                  onPress={() => {
                      setSpes(id)
                      if(id >= quantitySpeLength && quantity?.spe?.length > 0){
                          setQuantities(iterableArray(quantity?.spe.find((i) => i?.id == id)?.spe))
                      }
                  }}
                  />                
              </View>)
          })
          : null
          }
          </ScrollView>
          }
          
          
          { product?.product?.ingredients?.length > 0 && ingredients.find((it) => it?.add || (it?.remove && it?.isInitial)) && 
           <>
           {
               ingredients.find((it) => it.add) &&
               <View style={styles.view}>
                <View style={styles.header}>
                    <Text style={styles.textHeader}>
                        Ajouter des composants
                    </Text>
                </View>
                <View>
                    { 
                        ingredients.filter((i) => i?.add).map((i, ind) =>
                        <TouchableOpacity 
                        key={ind} 
                        style={[styles.item, {backgroundColor: colors.background}]}
                        onPress={() => {
                            if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'plus'})){
                                setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'plus'))
                            } else {
                                setQuantities([...iterableArray(quantities), {id: i.id, sign: 'plus', name: i.name, isFree: i?.isFreePlus ? true : false, costPrice: i?.p_costPrice_plus ? parseFloat(i?.p_costPrice_plus) : 0, price: i?.p_price_plus ? parseFloat(i?.p_price_plus) : 0}])
                            }
                        }}>
                            <Text style={[styles.textItem, {color: colors.text}]}>
                            {i.name} {!i?.isFreePlus && i?.p_price_plus && typeof i?.p_price_plus == "number" ? `(+${i?.p_price_plus.toFixed(2).replace('.', ',')}€)` : !i?.isFreePlus && i?.p_price_plus ? `${'(+'+i?.p_price_plus?.replace('.', ",")+"€)"}` : null}
                            </Text>
                            <SpeRadio
                                onPress={() => {
                                    if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'plus'})){
                                        setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'plus'))
                                    } else {
                                        setQuantities([...iterableArray(quantities), {id: i.id, sign: 'plus', name: i.name, isFree: i?.isFreePlus ? true : false, costPrice: i?.p_costPrice_plus ? parseFloat(i?.p_costPrice_plus) : 0, price: i?.p_price_plus ? parseFloat(i?.p_price_plus) : 0}])
                                    }
                                }}
                                isCheck={quantities.find((item2) => item2.id == i.id && item2.sign == 'plus') ? true : false}
                                borderColor={'#7F8FA6'}
                                checkedColor={'#008379'}
                                sign={'+'}
                            />
                        </TouchableOpacity>
                        )
                    }
                </View>
                </View>
           }
           {
               ingredients.find((it) => (it?.remove && it?.isInitial)) &&
               <View style={styles.view}>
                <View style={styles.header}>
                    <Text style={styles.textHeader}>
                        Retirer des composants
                    </Text>
                </View>
                <View>
                    { 
                        ingredients.filter((i) => (i?.remove && i?.isInitial)).map((i, ind) =>
                        <TouchableOpacity 
                        key={ind} 
                        style={[styles.item, {backgroundColor: colors.background}]}
                        onPress={() => {
                            if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'minus'})){
                                setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'minus'))
                            } else {
                                setQuantities([...iterableArray(quantities), {id: i.id, sign: 'minus', name: i.name, isFree: i?.isFreeMinus ? true : false, costPrice: i?.p_costPrice_minus ? i?.p_costPrice_minus : 0, price: i?.p_price_minus ? i?.p_price_minus : 0}])
                            }
                        }}>
                            <Text style={[styles.textItem, {color: colors.text}]}>
                            {i.name} {!i?.isFreeMinus && i?.p_price_minus && typeof i?.p_price_minus == "number" ? `(${i?.p_price_minus.toFixed(2).replace('.', ',')}€)` : !i?.isFreeMinus && i?.p_price_minus ? `${'('+i?.p_price_minus?.replace('.', ",")+"€)"}` : null}
                            </Text>
                            <SpeRadio
                               onPress={() => {
                                    if(quantities.find((item2) => {return item2.id == i.id && item2.sign == 'minus'})){
                                        setQuantities(quantities.filter((item2) => item2.id != i.id || item2.sign != 'minus'))
                                    } else {
                                        setQuantities([...iterableArray(quantities), {id: i.id, sign: 'minus', name: i.name, isFree: i?.isFreeMinus ? true : false, costPrice: i?.p_costPrice_minus ? i?.p_costPrice_minus : 0, price: i?.p_price_minus ? i?.p_price_minus : 0}])
                                    }
                                }}
                                isCheck={quantities.find((item2) => item2.id == i.id && item2.sign == 'minus') ? true : false}
                                borderColor={'#7F8FA6'}
                                checkedColor={'#008379'}
                                sign={'-'}
                            />
                        </TouchableOpacity>
                        )
                    }
                </View>
                </View>
           }
           </>
           }
        </View>
        
          <View style={styles.buttonSection}>
            <TouchableOpacity 
            onPress={() => {
                if(forEdit){
                    editQuantities(quantity?.spe?.length > spes ? null : spes)
                } else {
                    addQuantities(productId)
                }
            }} 
            style={styles.button}>
              <Text style={styles.buttonText}>
                 {forEdit ? "Modifier " : "Ajouter "}l'article
              </Text>
            </TouchableOpacity>
          </View>
          {
              forEdit ? 
              <View style={styles.buttonSection}>
                    <TouchableOpacity 
                    onPress={() => {
                        removeQuantities(quantity?.spe?.length > spes ? null : spes)
                    }} 
                    style={styles.buttonRemove}>
                    <Text style={[styles.buttonText, {color: '#ee5253'}]}>
                        Supprimer l'article
                    </Text>
                    </TouchableOpacity>
                </View>
                : null
          }
        </ScrollView>
        </View>
      );
  }
 

return (
    <View style={[styles.container, {backgroundColor: colors.background, position: 'relative'}]}>
        <View style={{height: ThemeUtils.APPBAR_HEIGHT,
         alignItems: 'center',
         justifyContent: 'center',
         }}>
        <Header
          scrollY={scrollY}
          title={"Restaurant"}
        />
        <Image 
        source={require('../../img/logo-dark.png')} 
        style={{height: 30, width:120}}/>
        </View>
        
        <View
          style={{
            position: 'absolute',
              top:ThemeUtils.APPBAR_HEIGHT,
              right:0,
              left:0,
              zIndex: 30
          }}
        >
        <ScrollView 
            contentContainerStyle={{ 
              padding:10, 
              height: 55, 
            }}
            horizontal
            showsHorizontalScrollIndicator={false}
            >
         { keys.length > 0 ?
            keys.map((item,index) =>
                <TouchableOpacity 
                key={index}
                style={[styles.buttonKey, {borderColor: dark ? "#fff" : "#34495e", backgroundColor: dark ? "#34495e" : "#fff"}]}
                onPress={() => downButtonHandler(index)}
                >
                <Text style={[styles.key, {color: dark ? "#fff" : "#34495e"}]}>
                    {item}
                </Text>
                </TouchableOpacity>
            )
            : null
            }
        </ScrollView>
        </View>
       
    <View style={[styles.subContainer, {
      paddingBottom: notEmpty() ? height*0.1 : 0
    }]}>
         
        { grouped.length > 0 ?
            <FlatList
            onScroll={Animated.event([
              {
                nativeEvent: {contentOffset: {y: scrollY}},
              },
            ])}
            ref={scrollview_ref}
            data={grouped}
            keyExtractor={(item, index) => index}
            renderItem={({item,index}) =>{
               
                return <View
                  key={index}
                  onLayout={event => {
                    const layout = event.nativeEvent.layout;
                    arr[index] = layout.y;
                  }}>
                    <View>
                      <Text style={[styles.category, {color: dark ? "#fff" : "#000"}]}>{item[0].category}</Text>
                    </View>
                    <View>
                      { item.map((item,index) => {
                         
                          return  <ListItem
                          theme={theme}
                          key={index}
                          isMenuList={item?.menu && item?.menu?.menuItems?.findIndex((i) => getProduct(i.productId)?.product?.ingredients?.find((it) => it.add || (it.remove && it.isInitial))) > -1}
                          item={item?.product ? item.product : item?.menu ? item.menu : null}
                          quantities={item?.product ? quantitiesProduct.find((it) => it.id == item.id) : quantitiesMenu.find((it) => it.id == item.id)}
                          // divisions={this.getDivisions(item.id)}
                          callback={() => {
                            if((item?.product && !item?.product?.isUnlimited && ((typeof item?.product?.p_realAvailable == "number" && item?.product?.p_realAvailable <= quantitiesProduct.find((it) => it.id == item.id)?.quantity) || (typeof item?.product?.p_realAvailable == "number" && item?.product?.p_realAvailable <= getQuantities(item?.id)) || (item?.product?.p_available <= getQuantities(item?.id)) || (item?.product?.p_available <= quantitiesProduct.find((it) => it.id == item.id)?.quantity))) || (item?.menu && !item?.menu?.isUnlimited && ((typeof item?.menu?.p_realAvailable == "number" && item?.menu?.p_realAvailable <= quantitiesMenu.find((it) => it.id == item.id)?.quantity) || item?.menu?.p_available <= quantitiesMenu.find((it) => it.id == item.id)?.quantity || getQMenu(item.id)))){
                                showMessage({
                                    message: `Vous avez atteint la limite des stocks disponibles`,
                                    type: 'warning',
                                    icon: 'warning',
                                  });
                              } else if((item?.product && !item?.product?.isUnlimited && item?.product?.p_available <= quantitiesProduct.find((it) => it.id == item.id)?.quantity) || (item?.menu && !item?.menu?.isUnlimited && item?.menu?.p_available <= quantitiesMenu.find((it) => it.id == item.id)?.quantity)){
                                showMessage({
                                    message: `La quantité maximale par commande pour ce ${item?.product ? "produit" : "menu"} est de ${item?.product ? item?.product?.p_max : item?.menu?.p_max}`,
                                    type: 'warning',
                                    icon: 'warning',
                                  });
                              } else {
                                if(item?.product && item?.product?.ingredients?.length > 0 && item?.product?.ingredients?.find((it) => it.add || (it.remove && it.isInitial))){
                                    setProductId(item?.id)
                                  } else if(item?.product && (!item?.product?.ingredients || !item?.product?.ingredients?.find((it) => it.add || (it.remove && it.isInitial)))){
                                    addQuantities(item?.id)
                                  } else if(item?.menu && (item?.menu?.options?.find((i) => i?.listItems?.length > 0) || item?.menu?.menuItems?.find((i) => getProduct(i.productId)?.product?.ingredients?.find((it) => it.add || (it.remove && it.isInitial))) )){
                                    setMenuId(item?.id)
                                  } else if(item?.menu){
                                    addQuantitiesMenu(item?.id)
                                  }
                              }
                              
                          }}
                          callbackEdit={() => {
                            if(item?.product && item?.product?.ingredients?.length > 0 && item?.product?.ingredients?.find((it) => it.add || (it.remove && it.isInitial))){
                                const quantity = quantitiesProduct.find((i ) => i.id == item?.id)
                                setProductId(item?.id)
                                setForEdit(true)
                                if(quantity?.spe?.length){
                                    setSpes(quantity?.spe[0]?.id)
                                    setQuantities(iterableArray(quantity?.spe.find((i) => i?.id == quantity?.spe[0]?.id)?.spe))
                                } else {
                                    setSpes(0)
                                }
                            } else if(isEditable(item)){
                                const quantity = quantitiesMenu.find((i ) => i.id == item?.id)
                                setMenuId(item?.id)
                                setForEdit(true)
                                if(quantity?.spe?.length){
                                    setSpes(quantity?.spe[0]?.id)
                                    
                                    setQuantities(iterableArray(quantity?.spe?.find((i) => i?.id == quantity?.spe[0]?.id)?.spe))
                                    setQuantitiesM(iterableArray(quantity?.menus?.find((i) => i?.id == quantity?.spe[0]?.id)?.menu))
                                } else if(quantity?.menus?.length){
                                    setSpes(quantity?.menus[0]?.id)
                                    setQuantities(iterableArray(quantity?.spe?.find((i) => i?.id == quantity?.menus[0]?.id)?.spe))
                                    setQuantitiesM(iterableArray(quantity?.menus.find((i) => i?.id == quantity?.menus[0]?.id)?.menu))
                                } else {
                                    setM(0)
                                    setSpes(0)
                                }
                            }
                        }}
                        callbackRemove={() => {
                            removeQuantities(null, item?.id)
                        }}
                           />
                      }
                    )}
                    </View>
                   
                  </View>
                }}
            />
           
            : null
          }
           
    </View>
    { notEmpty() ? 
        <TouchableOpacity 
        onPress={() => {
            if(getTotal() >= 15){
              setShowCart(true)
            } else {
              showMessage({
                message: 'Le montant de votre panier doit être supérieur à 15€',
                icon: 'warning',
                type: 'warning'
              })
            }
        }} 
        style={[styles.cartContainer, { 
          height: height*0.1,
          position: 'absolute',
          bottom: 0 
        }]}
        > 
        <Text> </Text>
        <Text style={{color: '#fff', fontWeight: 'bold'}}>Afficher le panier</Text>
        <Text style={{color: '#fff', fontWeight: 'bold'}}>{getTotal().toFixed(2).replace('.',',')}€</Text>
        </TouchableOpacity>
        : null
    }
    </View>
  )



});
export default Restaurant;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
  },
  shopTitle: {
    paddingLeft: 20,
    width: width * 0.6,
  },
  shopTitleText: {
    fontSize: 18,
  },
  shopImage: {
    width: width * 0.2,
    height: width * 0.25,
    borderRadius: 6,
  },
  shopContainer: {
    padding: 20,
    flexDirection: 'row',
  },
  contentContainerStyle: {
    justifyContent: 'center',
    alignItems: "center"
  },
  subContainer: {
    height: Dimensions.get("window").height*0.8,
    paddingTop: 55,
   },
  content: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
},
row_main: {
    height: Dimensions.get("window").height * 0.3,
    justifyContent: 'center',
    alignItems: 'center',
},
image_container: {

    justifyContent: 'center',
    alignItems: 'center',
},
item_img_style: {
    width: Dimensions.get("window").height * 0.055,
    height: Dimensions.get("window").height * 0.055,
    resizeMode: 'contain',
},
item_text: {
    fontWeight: 'bold',
    color: '#ffffff',
    fontSize: 16,
    marginTop: Dimensions.get("window").height * 0.03,
},
notification_circle: {
    position: 'absolute',
    top: 0,
    left: Dimensions.get("window").height * 0.045,
    width: Dimensions.get("window").height * 0.026,
    height: Dimensions.get("window").height * 0.026,
    borderRadius: Dimensions.get("window").height * 0.013,
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
},
notification: {
    color: 'white',
    backgroundColor: 'transparent',
},
 commentText: {
  fontSize:16,
  fontWeight: 'bold',
  color: "#555"
 },
 comment: {
  margin:10
 },
 cartContainer: { 
  backgroundColor:'#008379',
  width,
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
},
 containerImage: {
  position: 'absolute',
 },
 center:{ 
  justifyContent: 'center',
  alignItems: 'center' 
},
 image:{
    width,
    height: 180,
 },
 address:{
  fontSize: 14,
  marginHorizontal: 20,
  marginBottom: 8,
  color: "#888"
 },
 description:{
  fontSize: 14,
  marginHorizontal: 20,
  marginBottom: 8,
  color: "#888"
 },
 title: {
    fontSize:25,
    marginHorizontal: 20,
    marginVertical: 8,
    fontWeight: 'bold',
    color: "#555"
 },
 star: {
  width:10, 
  height: 10,
  marginLeft: 3
},
containerStar: {
  marginHorizontal:20,
  width:70
},
containerProducts: {
  marginTop: 20,
},
favorite:
{ 
  position: 'absolute',
  backgroundColor: '#fff',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex:20,
  right:20, 
  top:130,
  height:70, 
  width:70,
  borderRadius:100, 
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5,
},
notice: {
  flexDirection: 'row',
  justifyContent: 'space-between'
},
category: {
  margin:20,
  fontSize:20
},
buttonKey: {
  margin:5,
  padding:5,
  borderRadius:50,
  backgroundColor: 'rgb(214, 238, 236)',
},
key: {
  color: '#008379',
},
button: {
  padding: 15,
  backgroundColor: '#008379',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius:10
},
buttonRemove: {
  //width: width * 0.25,
  height: 30,
  backgroundColor: '#ee525366',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 30,
  margin: 5,
  padding:3
},
buttonText: {
  color: '#fff',
  fontSize: 16,
  fontWeight: 'bold',
},
reload: {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff'
},
view: {
    backgroundColor: '#fff',
  },
  buttonSection: {
    margin:30,
    alignItems: 'center'
  },
 textHeader: {
    fontSize: 18,
    color: "#fff"
 },
 header: {
    borderBottomColor: '#ccc',
    padding: 20,

    backgroundColor: '#008379'
 },
 item: {
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between'
 },
 textItem: {
    fontSize: 16
 },
 billContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 36,
    flex:1
  },
  textInput: {
    width: Dimensions.get('window').width * 0.84,
    backgroundColor: "#FFFFFF",
    color: "#000",
    height: 45,
    borderRadius: 5,
    padding: 14,
  },
  center: {
    width: width * 0.25,
    justifyContent: 'center',
  },
  viewImage: {
    width: width * 0.25,
    alignItems: 'center',
  },
  cardImage: {
    width: width * 0.15,
    height: width * 0.15,
    margin:10
  },
  description: {
    fontSize: 12,
    color: 'gray',
  },
  star: {
    width: 10,
    height: 10,
    marginLeft: 3,
  },
  containerStar: {
    marginHorizontal: 20,
    width: 70,
  },
  column: {
    width: width * 0.35,
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 2,
  },
  price: {
    fontWeight: 'bold',
    marginLeft: 3,
    fontSize: 18,
    marginBottom: 5,
  },
  button: {
    height: 30,
    backgroundColor: 'rgb(214, 238, 236)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    margin: 5,
    padding:3
  },
  product: {
    fontSize: 17,
  },
  buttonText: {
    color: '#008379',
    fontSize: 16,
    fontWeight: 'bold',
  },
  addButtons: {
    flexDirection: 'row',
  },
  itemProduct: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: "#fff",
    borderRadius: 10
  },
  oldPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    margin: 5,
  },
  prices: {
    flexDirection: 'row',
  },
  badge: {
    position: 'absolute',
    backgroundColor: '#008379',
    padding: 5,
    borderRadius: 50,
    width: 25,
    height: 25,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBadge: {
    color: '#fff',
    fontSize: 12,
  },
});
