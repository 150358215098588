import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
const {width, height} = Dimensions.get('window');
function countProps(obj) {
  var count = 0;
  for (var k in obj) {
    if (obj.hasOwnProperty(k)) {
      count++;
    }
  }
  return count;
}

function objectEquals(v1, v2) {
  if (typeof v1 !== typeof v2) {
    return false;
  }

  if (typeof v1 === 'function') {
    return v1.toString() === v2.toString();
  }

  if (v1 instanceof Object && v2 instanceof Object) {
    if (countProps(v1) !== countProps(v2)) {
      return false;
    }
    var r = true;
    for (var k in v1) {
      r = objectEquals(v1[k], v2[k]);
      if (!r) {
        return false;
      }
    }
    return true;
  } else {
    return v1 === v2;
  }
}
const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
const groupByArr = arr => {
  if (!arr) {
    return [];
  }
  return arr.reduce((s, f) => {
    let index = `${f.id}`;
    s[index] = s[index] ?? [];
    s[index].push(f);
    return s;
  }, {});
};

const groupByArr2 = arr => {
  if (!arr) {
    return [];
  }
  return arr.reduce((s, f) => {
    let index = f.index;
    index = index.split('-');
    index.pop();
    index = index.join('-');
    s[index] = s[index] ?? [];
    s[index].push(f);
    return s;
  }, {});
};
const groupByArr3 = arr => {
  if (!arr) {
    return [];
  }
  return arr
    .filter(i => i.index?.split('-')?.length > 2)
    .reduce((s, f) => {
      let index = f.index;
      index = index.split('-');
      if (index.length == 4) {
        index.pop();
        index.pop();
      }
      if (index.length == 3) {
        index.pop();
      }
      // index.pop()
      index = index.join('-');
      s[index] = s[index] ?? [];
      s[index].push(f);
      return s;
    }, {});
};

function iterableArray(item) {
  if (item != undefined && Array.isArray(item)) {
    return item;
  } else {
    return [];
  }
}
export default class ProductListItem extends React.Component {
  renderButton(item, add, remove, it = null, bool = true) {
    const {colors, dark} = this.props.theme;
    const isSimilar = [...item?.spe?.filter(i => objectEquals(it, i.spe))];
    return (
      <View style={{flexDirection: 'row'}}>
        {(isSimilar?.length || item?.quantity > 0) && bool ? (
          <>
            <TouchableOpacity
              onPress={() => {
                remove();
              }}
              activeOpacity={0.5}>
              <View
                style={{
                  backgroundColor: '#008379',
                  width: 20,
                  height: 20,
                  borderRadius: 50,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{color: '#ffffff', fontWeight: 'bold'}}>-</Text>
              </View>
            </TouchableOpacity>
            <Text
              style={{marginHorizontal: 10, fontSize: 20, color: colors.text}}>
              {isSimilar?.length ? isSimilar?.length : item?.quantity}
            </Text>
            <TouchableOpacity
              onPress={() => {
                add();
              }}
              activeOpacity={0.5}>
              <View
                style={{
                  backgroundColor: '#008379',
                  width: 20,
                  height: 20,
                  borderRadius: 50,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{color: '#ffffff', fontWeight: 'bold'}}>+</Text>
              </View>
            </TouchableOpacity>
          </>
        ) : (
          <TouchableOpacity
            onPress={() => {
              add();
            }}
            activeOpacity={0.5}
            style={styles.button}>
            <Text style={styles.buttonText}>Ajouter</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  }

  getPriceProduct2 = (item, spe) => {
    let total = 0;
    if (!item.isFree && item?.quantity && item?.price) {
      total = total + item.price;
    }
    const e = spe?.spe?.forEach(item => {
      if (!item.isFree && item?.price) {
        total = total + item.price;
      }
    });
    return total;
  };

  getPriceMenu2 = (item, spe) => {
    let total = 0;
    if (!item.isFree && item?.quantity && item?.price) {
      total = total + item.price;
    }
    const e = spe?.forEach(item => {
      if (item?.spe) {
        const b = item?.spe?.forEach(item => {
          if (!item.isFree && item?.price) {
            total = total + item.price;
          }
        });
      }
      if (item?.menu) {
        const c = item?.menu?.forEach(item => {
          if (!item.isFree && item?.price) {
            total = total + item.price;
          }
        });
      }
    });
    return total;
  };

  getPriceProduct = item => {
    let total = 0;
    if (!item.isFree && item?.quantity && item?.price) {
      total = total + item.quantity * item.price;
    }
    const e = item?.spe?.forEach(item => {
      item?.spe?.forEach(item => {
        if (!item.isFree && item?.price) {
          total = total + item.price;
        }
      });
    });
    return total;
  };

  getOnePrice = item => {
    let total = 0;
    if (!item.isFree && item?.quantity && item?.price) {
      total = total + item.price;
    }

    return total;
  };

  getPriceMenu = item => {
    let total = 0;
    if (!item.isFree && item?.quantity && item?.price) {
      total = total + item.quantity * item.price;
    }
    const d = item?.menus?.forEach(item => {
      const f = item?.menu?.forEach(item => {
        if (!item.isFree && item?.price) {
          total = total + item.price;
        }
      });
    });
    const e = item?.spe?.forEach(item => {
      item?.spe?.forEach(item => {
        if (!item.isFree && item?.price) {
          total = total + item.price;
        }
      });
    });
    return total;
  };

  getAllPricesProducts = item => {
    let total = [];
    let c = item.forEach(item => {
      if (!item.isFree && item?.quantity && item?.price) {
        total = total + item.quantity * item.price;
      }
      const e = item?.spe?.forEach(item => {
        item?.spe?.forEach(item => {
          if (!item.isFree && item?.price) {
            total = total + item.price;
          }
        });
      });
    });
    return total;
  };

  getAllPricesMenus = item => {
    let total = [];
    let c = item.forEach(item => {
      if (!item.isFree && item?.quantity && item?.price) {
        total = total + item.quantity * item.price;
      }
      const d = item?.menus?.forEach(item => {
        const f = item?.menu?.forEach(item => {
          if (!item.isFree && item?.price) {
            total = total + item.price;
          }
        });
      });
      const e = item?.spe?.forEach(item => {
        item?.spe?.forEach(item => {
          if (!item.isFree && item?.price) {
            total = total + item.price;
          }
        });
      });
    });
    return total;
  };

  getMenuSpe = arr => {
    const menu = arr.find(i => i?.menu)?.menu;
    const spe = arr.find(i => i?.spe)?.spe;
    return {
      menu: menu ? menu : [],
      spe: spe ? spe : [],
    };
  };

  dash = item => {
    const dashedDivisions = item.map((i, index) => {
      if (index > 0) {
        return '-' + i.subclass;
      } else {
        return i.subclass;
      }
    });
    return dashedDivisions;
  };

  getProduct = id => {
    const products = [...this.props?.products];
    return products.find(it => it.id == id);
  };

  getPrice = id => {
    const prices = [...this.props?.prices];
    return prices.find(it => it.id == id);
  };

  getPack = id => {
    const packs = [...this.props?.packs];
    return packs.find(it => it.id == id);
  };

  render() {
    const {colors, dark} = this.props.theme;

    const {item, it, add, remove, products, menus, details} = this.props;
    const prices = this?.props?.prices ? this?.props?.prices : [];
    const packs = this?.props?.packs ? this?.props?.packs : [];
    let array = [];
    return (
      <>
        {!item?.menu &&
        item?.spe?.length > 0 &&
        Array.from(Array(item?.quantity ? item?.quantity : 0).keys()).length >
          0 ? (
          Array.from(Array(item?.quantity ? item?.quantity : 0).keys()).map(
            (i, index) => {
              const isLess = index < item?.spe?.length;
              const hasSimilar = isLess
                ? array?.findIndex(i =>
                    arraysEqual(iterableArray(item.spe[index].spe), i),
                  ) == -1 ||
                  item?.spe?.findIndex(i =>
                    arraysEqual(i?.spe, item.spe[index].spe),
                  ) == -1
                : false;
              const length = isLess
                ? [
                    ...item?.spe?.filter(i =>
                      objectEquals(item.spe[index].spe, i.spe),
                    ),
                  ].length
                : 0;

              if (isLess && hasSimilar) {
                if (item?.spe[index]?.spe) {
                  array.push(item.spe[index].spe);
                } else if (!item?.spe[index]?.spe) {
                  array.push([]);
                }
                return (
                  <View
                    style={[
                      styles.product,
                      {backgroundColor: colors.background},
                    ]}>
                    <View style={styles.productDescription}>
                      <View style={{flexDirection: 'column'}}>
                        <Text
                          numberOfLines={3}
                          style={{
                            width: width * 0.35,
                            fontSize: 16,
                            color: colors.text,
                          }}>
                          {it?.product?.p_name}
                        </Text>
                        {item?.spe[index]?.spe &&
                          item.spe[index].spe.map(i => (
                            <Text
                              numberOfLines={3}
                              style={[
                                {
                                  width: width * 0.35,
                                  color:
                                    i?.sign == 'minus'
                                      ? '#ee5253'
                                      : dark
                                      ? '#fff'
                                      : '#666',
                                },
                                i?.sign == 'minus'
                                  ? {
                                      textDecorationLine: 'line-through',
                                      textDecorationStyle: 'solid',
                                    }
                                  : null,
                              ]}>
                              {i?.name}
                            </Text>
                          ))}
                      </View>
                    </View>
                    {!details ? (
                      <View
                        style={{width: width * 0.3, justifyContent: 'center'}}>
                        {this.renderButton(
                          item,
                          () => add(item.id, item.spe[index].spe),
                          () => remove(item.spe[index]?.id, item.id),
                          item.spe[index].spe,
                        )}
                      </View>
                    ) : (
                      <View
                        style={{width: width * 0.45, justifyContent: 'center'}}>
                        <Text
                          style={{
                            marginHorizontal: 10,
                            fontSize: 20,
                            color: colors.text,
                          }}>
                          x{item?.quantity}
                        </Text>
                      </View>
                    )}

                    <View
                      style={{width: width * 0.2, justifyContent: 'center'}}>
                      <View>
                        <Text style={{color: colors.text}}>
                          {(
                            this.getPriceProduct2(item, item.spe[index]) *
                            length
                          )
                            .toFixed(2)
                            .replace('.', ',')}
                          €
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              } else if (!isLess) {
                return (
                  <View
                    style={[
                      styles.product,
                      {backgroundColor: colors.background},
                    ]}>
                    <View style={styles.productDescription}>
                      <View style={{flexDirection: 'column'}}>
                        <Text
                          numberOfLines={3}
                          style={{
                            width: width * 0.35,
                            fontSize: 16,
                            color: colors.text,
                          }}>
                          {it?.product?.p_name}
                        </Text>
                      </View>
                    </View>
                    {!details ? (
                      <View
                        style={{width: width * 0.3, justifyContent: 'center'}}>
                        {this.renderButton(
                          item,
                          () => add(item.id),
                          () => remove(null, item.id),
                          null,
                          false,
                        )}
                      </View>
                    ) : (
                      <View
                        style={{width: width * 0.45, justifyContent: 'center'}}>
                        <Text
                          style={{
                            marginHorizontal: 10,
                            fontSize: 20,
                            color: colors.text,
                          }}>
                          x{item?.quantity}
                        </Text>
                      </View>
                    )}

                    <View
                      style={{width: width * 0.2, justifyContent: 'center'}}>
                      <View>
                        <Text style={{color: colors.text}}>
                          {this.getOnePrice(item)
                            .toFixed(2)
                            .replace('.', ',')}
                          €
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              }
            },
          )
        ) : !item?.menu && it?.product ? (
          <View style={[styles.product, {backgroundColor: colors.background}]}>
            <View style={styles.productDescription}>
              <View style={{flexDirection: 'column'}}>
                <Text
                  numberOfLines={3}
                  style={{
                    width: width * 0.35,
                    fontSize: 16,
                    color: colors.text,
                  }}>
                  {it?.product?.p_name}
                </Text>
              </View>
            </View>
            {!details ? (
              <View style={{width: width * 0.3, justifyContent: 'center'}}>
                {this.renderButton(
                  item,
                  () => add(item.id),
                  () => remove(null, item.id),
                  null,
                  false,
                )}
              </View>
            ) : (
              <View style={{width: width * 0.45, justifyContent: 'center'}}>
                <Text
                  style={{
                    marginHorizontal: 10,
                    fontSize: 20,
                    color: colors.text,
                  }}>
                  x{item?.quantity}
                </Text>
              </View>
            )}

            <View style={{width: width * 0.2, justifyContent: 'center'}}>
              <View>
                <Text style={{color: colors.text}}>
                  {this.getOnePrice(item)
                    .toFixed(2)
                    .replace('.', ',')}
                  €
                </Text>
              </View>
            </View>
          </View>
        ) : item?.menu &&
          (item?.spe?.length > 0 || item?.menus?.length > 0) > 0 &&
          Array.from(Array(item?.quantity ? item?.quantity : 0).keys()).length >
            0 ? (
          Array.from(Array(item?.quantity ? item?.quantity : 0).keys()).map(
            (i, index) => {
              let all = Object.values(
                groupByArr([
                  ...iterableArray(item?.menus),
                  ...iterableArray(item?.spe),
                ]),
              );
              const menu = menus.find(i => i.id == item.id);
              const isLess = index < all.length;
              const hasSimilar = isLess
                ? array?.findIndex(i =>
                    objectsEqual(this.getMenuSpe(all[index]), i),
                  ) == -1 ||
                  all?.findIndex(i =>
                    objectsEqual(
                      this.getMenuSpe(i),
                      this.getMenuSpe(all[index]),
                    ),
                  ) == -1
                : false;
              const length = isLess
                ? [
                    ...all.filter(i =>
                      objectsEqual(
                        this.getMenuSpe(all[index]),
                        this.getMenuSpe(i),
                      ),
                    ),
                  ].length
                : 0;
              if (isLess && hasSimilar) {
                array.push(this.getMenuSpe(all[index]));
                return (
                  <View
                    style={[
                      styles.product,
                      {backgroundColor: colors.background},
                    ]}>
                    <View style={styles.productDescription}>
                      <View style={{flexDirection: 'column'}}>
                        <Text
                          numberOfLines={3}
                          style={{
                            width: width * 0.35,
                            fontSize: 18,
                            color: colors.text,
                          }}>
                          {it?.menu?.p_name}
                        </Text>

                        {menu?.menu?.menuItems?.map((i, ind) => {
                          return (
                            <>
                              {Array.from(
                                Array(i?.quantity ? i?.quantity : 0).keys(),
                              ).map((it, index2) => {
                                const items = Object.values(
                                  groupByArr3([
                                    ...iterableArray(
                                      all[index]?.find(i => i?.spe)?.spe,
                                    ),
                                    ...iterableArray(
                                      all[index]?.find(i => i?.menu)?.menu,
                                    ),
                                  ]),
                                )?.filter((i2, index) => i2[0].itemId == i.id);
                                const length = items?.length;
                                return (
                                  <>
                                    <Text
                                      numberOfLines={3}
                                      style={[
                                        {
                                          width: width * 0.35,
                                          color: colors.text,
                                          fontSize: 14,
                                        },
                                      ]}>
                                      {i?.name}
                                    </Text>
                                    {Array.from(Array(length).keys()).map(
                                      (item, ind) => (
                                        <>
                                          {items[item]?.length > 0 &&
                                            items[item].map(item => {
                                              if (ind == index2) {
                                                return (
                                                  <Text
                                                    numberOfLines={3}
                                                    style={[
                                                      {
                                                        width: width * 0.35,
                                                        paddingLeft: 5,
                                                        color:
                                                          item?.sign == 'minus'
                                                            ? '#ee5253'
                                                            : dark
                                                            ? '#fff'
                                                            : '#666',
                                                        fontSize: 12,
                                                      },
                                                      item?.sign == 'minus'
                                                        ? {
                                                            textDecorationLine:
                                                              'line-through',
                                                            textDecorationStyle:
                                                              'solid',
                                                          }
                                                        : null,
                                                    ]}>
                                                    {item?.sign == 'minus'
                                                      ? '-'
                                                      : '+'}{' '}
                                                    {item?.name}
                                                  </Text>
                                                );
                                              }
                                            })}
                                        </>
                                      ),
                                    )}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                        {[
                          ...iterableArray(
                            all[index]?.find(i => i?.menu)?.menu,
                          ),
                        ]
                          ?.filter(i => i.optionsProduct)
                          .map(i => (
                            <Text
                              numberOfLines={3}
                              style={[
                                {
                                  width: width * 0.35,
                                  color: colors.text,
                                  fontSize: 14,
                                },
                              ]}>
                              {i?.name}
                            </Text>
                          ))}
                      </View>
                    </View>
                    {!details ? (
                      <View
                        style={{width: width * 0.3, justifyContent: 'center'}}>
                        {this.renderButton(
                          item,
                          () => add(item.id, all[index]),
                          () => remove(all[index][0]?.id, item.id),
                          item.spe[index].spe,
                        )}
                      </View>
                    ) : (
                      <View
                        style={{width: width * 0.45, justifyContent: 'center'}}>
                        <Text
                          style={{
                            marginHorizontal: 10,
                            fontSize: 20,
                            color: colors.text,
                          }}>
                          x{item?.quantity}
                        </Text>
                      </View>
                    )}

                    <View
                      style={{width: width * 0.2, justifyContent: 'center'}}>
                      <View>
                        <Text style={{color: colors.text}}>
                          {(this.getPriceMenu2(item, all[index]) * length)
                            .toFixed(2)
                            .replace('.', ',')}
                          €
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              } else if (!isLess) {
                return (
                  <View
                    style={[
                      styles.product,
                      {backgroundColor: colors.background},
                    ]}>
                    <View style={styles.productDescription}>
                      <View style={{flexDirection: 'column'}}>
                        <Text
                          numberOfLines={3}
                          style={{
                            width: width * 0.35,
                            fontSize: 16,
                            color: colors.text,
                          }}>
                          {it?.product?.p_name}
                        </Text>
                        {menu?.menu?.menuItems?.map((i, ind) => {
                          return (
                            <>
                              {Array.from(
                                Array(i?.quantity ? i?.quantity : 0).keys(),
                              ).map((it, index2) => (
                                <>
                                  <Text
                                    numberOfLines={3}
                                    style={[
                                      {
                                        width: width * 0.35,
                                        color: colors.text,
                                        fontSize: 15,
                                      },
                                    ]}>
                                    {i?.name}
                                  </Text>
                                </>
                              ))}
                            </>
                          );
                        })}
                      </View>
                    </View>
                    {!details ? (
                      <View
                        style={{width: width * 0.3, justifyContent: 'center'}}>
                        {this.renderButton(
                          item,
                          () => add(item.id),
                          () => remove(null, item.id),
                          null,
                          false,
                        )}
                      </View>
                    ) : (
                      <View
                        style={{width: width * 0.45, justifyContent: 'center'}}>
                        <Text
                          style={{
                            marginHorizontal: 10,
                            fontSize: 20,
                            color: colors.text,
                          }}>
                          x{item?.quantity}
                        </Text>
                      </View>
                    )}

                    <View
                      style={{width: width * 0.2, justifyContent: 'center'}}>
                      <View>
                        <Text style={{color: colors.text}}>
                          {this.getOnePrice(item)
                            .toFixed(2)
                            .replace('.', ',')}
                          €
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              }
            },
          )
        ) : item?.menu && (item?.spe?.length > 0 || item?.menus?.length > 0) ? (
          <View style={[styles.product, {backgroundColor: colors.background}]}>
            <View style={styles.productDescription}>
              <View style={{flexDirection: 'column'}}>
                <Text
                  numberOfLines={3}
                  style={{
                    width: width * 0.35,
                    fontSize: 16,
                    color: colors.text,
                  }}>
                  {it?.product?.p_name}
                </Text>
                {menu?.menu?.menuItems?.map((i, ind) => {
                  return (
                    <>
                      {Array.from(
                        Array(i?.quantity ? i?.quantity : 0).keys(),
                      ).map((it, index2) => (
                        <>
                          <Text
                            numberOfLines={3}
                            style={[
                              {
                                width: width * 0.35,
                                color: colors.text,
                                fontSize: 15,
                              },
                            ]}>
                            {i?.name}
                          </Text>
                        </>
                      ))}
                    </>
                  );
                })}
              </View>
            </View>
            {!details ? (
              <View style={{width: width * 0.3, justifyContent: 'center'}}>
                {this.renderButton(
                  item,
                  () => add(item.id),
                  () => remove(null, item.id),
                  null,
                  false,
                )}
              </View>
            ) : (
              <View style={{width: width * 0.45, justifyContent: 'center'}}>
                <Text
                  style={{
                    marginHorizontal: 10,
                    fontSize: 20,
                    color: colors.text,
                  }}>
                  x{item?.quantity}
                </Text>
              </View>
            )}

            <View style={{width: width * 0.2, justifyContent: 'center'}}>
              <View>
                <Text style={{color: colors.text}}>
                  {this.getOnePrice(item)
                    .toFixed(2)
                    .replace('.', ',')}
                  €
                </Text>
              </View>
            </View>
          </View>
        ) : item?.packItems ? (
          <View style={[styles.product, {backgroundColor: colors.background}]}>
            <View style={styles.productDescription}>
              <View style={{flexDirection: 'column'}}>
                <Text
                  numberOfLines={3}
                  style={{
                    width: width * 0.35,
                    fontSize: 16,
                    color: colors.text,
                  }}>
                  {it?.pack?.p_name}
                </Text>
                {item?.packItems?.map((i, ind) => {
                  return (
                    <>
                      {Array.from(
                        Array(i?.quantity ? i?.quantity : 0).keys(),
                      ).map((it, index2) => (
                        <>
                          <Text
                            numberOfLines={3}
                            style={[
                              {
                                width: width * 0.35,
                                color: colors.text,
                                fontSize: 15,
                              },
                            ]}>
                            {i?.productId
                              ? this?.getProduct(i?.productId)?.product?.p_name
                              : i?.priceId
                              ? this?.getPrice(i?.priceId)?.price?.p_name
                              : ''}
                          </Text>
                        </>
                      ))}
                    </>
                  );
                })}
              </View>
            </View>
            {!details ? (
              <View style={{width: width * 0.45, justifyContent: 'center'}}>
                {this.renderButton(
                  item,
                  () => add(item.id),
                  () => remove(null, item.id),
                  null,
                  false,
                )}
              </View>
            ) : (
              <View style={{width: width * 0.45, justifyContent: 'center'}}>
                <Text
                  style={{
                    marginHorizontal: 10,
                    fontSize: 20,
                    color: colors.text,
                  }}>
                  x{item?.quantity}
                </Text>
              </View>
            )}

            <View style={{width: width * 0.2, justifyContent: 'flex-end'}}>
              <View>
                <Text style={{color: colors.text}}>
                  {this.getOnePrice(item)
                    .toFixed(2)
                    .replace('.', ',')}
                  €
                </Text>
              </View>
            </View>
          </View>
        ) : (
          <View style={[styles.product, {backgroundColor: colors.background}]}>
            <View style={styles.productDescription}>
              <View style={{flexDirection: 'column'}}>
                <Text
                  numberOfLines={3}
                  style={{
                    width: width * 0.35,
                    fontSize: 16,
                    color: colors.text,
                  }}>
                  {it?.price?.p_name}
                </Text>
              </View>
            </View>
            {!details ? (
              <View style={{width: width * 0.45, justifyContent: 'center'}}>
                {this.renderButton(
                  item,
                  () => add(item.id),
                  () => remove(null, item.id),
                  null,
                  false,
                )}
              </View>
            ) : (
              <View style={{width: width * 0.45, justifyContent: 'center'}}>
                <Text
                  style={{
                    marginHorizontal: 10,
                    fontSize: 20,
                    color: colors.text,
                  }}>
                  x{item?.quantity}
                </Text>
              </View>
            )}

            <View style={{width: width * 0.2, justifyContent: 'flex-end'}}>
              <View>
                <Text style={{color: colors.text}}>
                  {this.getOnePrice(item)
                    .toFixed(2)
                    .replace('.', ',')}
                  €
                </Text>
              </View>
            </View>
          </View>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
  subContainer: {
    position: 'relative',
    backgroundColor: '#f9f9f9',
    height: height * 0.8,
  },
  subContainerTwo: {
    height: height * 0.2,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  shopImage: {
    width: width * 0.25,
    height: width * 0.25,
    borderRadius: 6,
  },
  shopContainer: {
    padding: 20,
    flexDirection: 'row',
  },
  titleContainer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  titleText: {
    fontSize: 14,
    color: '#666',
  },
  shopTitle: {
    paddingLeft: 20,
    width: width * 0.6,
  },
  shopTitleText: {
    fontSize: 18,
  },
  shopAddressText: {
    fontSize: 12,
    color: '#555',
    paddingLeft: 5,
  },
  shopAddress: {
    flexDirection: 'row',
    marginTop: 10,
  },
  productsContainer: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  productImage: {
    width: width * 0.15,
    height: width * 0.15,
  },
  product: {
    padding: 10,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  productDescription: {
    width: width * 0.4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  addButtons: {
    flexDirection: 'row',
  },
  totalContainer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  button: {
    width: width * 0.8,
    height: height * 0.08,
    backgroundColor: '#008379',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textButton: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#fff',
  },
});
