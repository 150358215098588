import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

// @ts-ignore
function SvgComponent(props) {
  return (
    <Svg viewBox="0 0 80 80" width={80} height={80} {...props}>
        <Path d="M50.7984 0.952864C47.5928 1.68886 44.8936 4.40406 44.2184 7.59366C43.252 12.1185 45.8904 16.5353 50.3528 17.8545C51.4728 18.1769 53.5432 18.2225 54.724 17.9313C57.6992 17.1953 60.1992 14.7873 61.0736 11.7961C61.472 10.4617 61.4112 8.17686 60.9352 6.78086C60.0304 4.09686 57.9448 1.99606 55.368 1.19846C54.0496 0.784062 52.0248 0.676864 50.7984 0.952864Z" fill="white"/>
        <Path d="M18.3902 2.21049C14.2494 3.31449 11.5806 7.62409 12.455 11.7809C12.823 13.4985 13.4982 14.7409 14.7558 16.0137C16.2894 17.5473 17.8382 18.2681 19.955 18.4673C23.927 18.8049 27.8534 15.8905 28.651 11.9793C28.9118 10.7521 28.7734 8.52809 28.375 7.43929C27.179 4.12649 24.0654 1.94889 20.5534 1.97929C19.8166 1.98089 18.8502 2.08809 18.3902 2.21049Z" fill="white"/>
        <Path d="M48.8194 21.5353C47.1938 21.7193 44.2946 22.2713 43.2522 22.6089C40.7058 23.4521 38.973 24.7257 38.6202 26.0601C38.513 26.4433 38.4666 30.4001 38.4978 37.4249C38.5434 47.6089 38.5594 48.2377 38.8354 48.7433C39.2338 49.5097 39.6786 49.9553 40.3842 50.3233C41.0434 50.6609 42.3626 50.7681 43.0986 50.5225L43.5282 50.4001L43.5586 63.0225C43.6042 75.5681 43.6042 75.6601 43.9266 76.3657C44.4018 77.3937 45.261 78.2673 46.2578 78.7585C46.9938 79.1113 47.3314 79.1881 48.2826 79.1729C50.0466 79.1729 51.365 78.4521 52.2858 76.9953L52.7002 76.3361L53.0066 76.8425C53.4978 77.6401 54.433 78.4529 55.261 78.8361C56.3962 79.3577 58.037 79.3113 59.1258 78.7593C60.0922 78.2689 60.8898 77.5017 61.3498 76.6121L61.7026 75.9681L61.749 63.1769L61.7794 50.4009L62.1322 50.4929C63.6354 50.9073 64.893 50.6465 65.813 49.6953C66.8866 48.5913 66.841 49.1889 66.841 37.0577C66.841 26.2601 66.841 26.1377 66.5186 25.4785C65.7522 23.8833 63.0834 22.6105 59.0186 21.8745C57.6074 21.6289 56.473 21.5521 53.3442 21.5217C51.1962 21.4889 49.1562 21.5049 48.8194 21.5353Z" fill="white"/>
        <Path d="M17.0718 21.689C11.6886 22.241 8.2222 23.6522 7.3174 25.661C7.0566 26.213 7.0262 26.7498 7.0262 30.8298V35.4002H4.3422H1.6582V37.3938V39.3882H18.6822H35.7062V37.3946V35.4002H34.863H34.0198V30.7066C34.0198 25.4154 34.0198 25.385 32.8846 24.4186C31.627 23.3298 29.3574 22.4858 26.351 21.965C24.9398 21.7346 18.559 21.5354 17.0718 21.689Z" fill="white"/>
        <Path d="M69.6312 37.3483L69.6768 39.3115L74.0168 39.3571L78.3416 39.3875V37.3939V35.4003H73.9704H69.584L69.6312 37.3483Z" fill="white"/>

    </Svg>
  );
}

const SvgUn_follow = React.memo(SvgComponent);
export default SvgUn_follow;
