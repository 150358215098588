const Color = {
  STATUSBAR_COLOR: '#008379',
  HEADER_COLOR: '#008379',
  CARD_BG_COLOR: '#ffffff',
  HEADER_TEXT_COLOR: '#ffffff',
  HEADER_BACK_ICON_COLOR: '#ffffff',
  BLACK: '#000000',
  F_COLOR: '#008379',
  S_COLOR: '#008379',
  
};

export default Color;
