import React from 'react';
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  FlatList,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
} from 'react-native';

const {width, height} = Dimensions.get('window');

export default class ShopListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  priceDrop = (new_price, old_price) => {
    return Math.round((new_price / old_price - 1) * 100) + '%';
  };

  renderButton() {
    const {colors, dark} = this.props.theme;
    return (
      <View style={styles.addButtons}>
        {this.props.item &&
        !this.props.isMenuList &&
        (!this.props.item?.ingredients ||
          !this.props.item?.ingredients?.find(
            it => it.add || (it.remove && it.isInitial),
          )) &&
        !this.props?.item?.options?.find(item => {
          return item?.listItems?.length > 0;
        }) ? (
          <>
            {this.props.quantities?.quantity > 0 ? (
              <>
                <TouchableOpacity
                  onPress={() => {
                    this.props.callbackRemove();
                  }}
                  activeOpacity={0.5}>
                  <View
                    style={{
                      backgroundColor: '#008379',
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text style={{color: '#ffffff', fontWeight: 'bold'}}>
                      -
                    </Text>
                  </View>
                </TouchableOpacity>
                <Text
                  style={{
                    marginHorizontal: 10,
                    fontSize: 20,
                    color: colors.text,
                  }}>
                  {this.props.quantities?.quantity}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.props.callback();
                  }}
                  activeOpacity={0.5}>
                  <View
                    style={{
                      backgroundColor: '#008379',
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text style={{color: '#ffffff', fontWeight: 'bold'}}>
                      +
                    </Text>
                  </View>
                </TouchableOpacity>
              </>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  this.props.callback();
                }}
                activeOpacity={0.5}
                style={styles.button}>
                <Text style={styles.buttonText}>Ajouter</Text>
              </TouchableOpacity>
            )}
          </>
        ) : (
          <View style={{flexDirection: 'row'}}>
            <View style={{flexDirection: 'column'}}>
              <TouchableOpacity
                onPress={() => {
                  this.props.callback();
                }}
                activeOpacity={0.5}
                style={styles.button}>
                <Text style={styles.buttonText}>Ajouter</Text>
              </TouchableOpacity>
              {this.props.quantities?.quantity > 0 ? (
                <TouchableOpacity
                  onPress={() => {
                    this.props.callbackEdit();
                  }}
                  activeOpacity={0.5}
                  style={styles.button}>
                  <Text style={styles.buttonText}>Modifier</Text>
                </TouchableOpacity>
              ) : null}
              {this.props.quantities?.quantity > 0 ? (
                <View style={{position: 'absolute', top: 0, right: 10}}>
                  <View style={styles.badge}>
                    <Text style={styles.textBadge}>
                      {this.props.quantities?.quantity
                        ? this.props.quantities?.quantity
                        : 0}
                    </Text>
                  </View>
                </View>
              ) : null}
            </View>
          </View>
        )}
      </View>
    );
  }

  render() {
    const {colors, dark} = this.props.theme;
    const {item, callback} = this.props;
    return (
      <View
        style={[styles.itemProduct, {backgroundColor: colors.backgroundColor}]}>
          {item?.image?.url ? (
          <View style={styles.viewImage}>
            <Image style={styles.cardImage} source={{uri: item.image.url}} />
          </View>
          ) : null}
        <View style={styles.column}>
          <Text
            numberOfLines={2}
            style={[styles.product, {color: colors.text}]}>
            {item.p_name}
          </Text>
          <Text style={[styles.description, {color: '#666'}]}>
            {item?.isUnlimited
              ? 'Illimité'
              : typeof item?.p_realAvailable == 'number' &&
                item?.p_realAvailable > 0 &&
                item?.p_realAvailable <= 20
              ? `Plus que ${item?.p_realAvailable} disponible(s)`
              : item?.p_available <= 20
              ? `Plus que ${item?.p_available} disponible(s)`
              : ''}
          </Text>
          {item?.p_description?.length > 200 ? (
            <Text style={styles.description}></Text>
          ) : (
            <Text style={styles.description}>{item.p_description}</Text>
          )}
          <Text style={[styles.price, {color: colors.text}]}>
            {item?.isFree
              ? 'Gratuit'
              : `${item.p_price.toFixed(2).replace('.', ',')}€`}
          </Text>
        </View>
        <View style={styles.center}>{this.renderButton()}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  center: {
    width: width * 0.25,
    justifyContent: 'center',
  },
  viewImage: {
    width: width * 0.25,
    alignItems: 'center',
  },
  cardImage: {
    width: width * 0.15,
    height: width * 0.15,
    margin:10
  },
  description: {
    fontSize: 12,
    color: 'gray',
  },
  star: {
    width: 10,
    height: 10,
    marginLeft: 3,
  },
  containerStar: {
    marginHorizontal: 20,
    width: 70,
  },
  column: {
    width: width * 0.35,
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 2,
  },
  price: {
    fontWeight: 'bold',
    marginLeft: 3,
    fontSize: 18,
    marginBottom: 5,
  },
  button: {
    width: width * 0.25,
    height: 30,
    backgroundColor: 'rgb(214, 238, 236)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    margin: 5,
  },
  product: {
    fontSize: 17,
  },
  buttonText: {
    color: '#008379',
    fontSize: 16,
    fontWeight: 'bold',
  },
  addButtons: {
    flexDirection: 'row',
  },
  itemProduct: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: 15,
    backgroundColor: "#fff",
    borderRadius: 10
  },
  oldPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    margin: 5,
  },
  prices: {
    flexDirection: 'row',
  },
  badge: {
    position: 'absolute',
    backgroundColor: '#008379',
    padding: 5,
    borderRadius: 50,
    width: 25,
    height: 25,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBadge: {
    color: '#fff',
    fontSize: 12,
  },
});
