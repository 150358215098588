import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  Dimensions,
  Image,
  ImageBackground,
  TouchableOpacity,
  TextInput
} from 'react-native';
import Product from './ProductListItem';
import Alert from 'react-native-awesome-alerts';

const {width, height} = Dimensions.get('window');
function iterableArray(item) {
  if (item != undefined && Array.isArray(item)) {
    return item;
  } else {
    return [];
  }
}
class CartScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: 0,
      loading: false,
      allowed: false,
      token: null,
      notes: '',
      visibleNote: false
    };
  }

  _listProduct = () => {
    let arr = [];
    let cart = this.props.cart.filter(
      product => product.shopId == this.props.route.params.shop.id,
    );
    for (var i = 0; i < cart.length; i++) {
      const id = cart.findIndex(p => p.id === cart[i].id);
      if (id >= 0) {
        arr = [...arr, cart[id]];
      }
    }
    return arr;
  };


  navigation = () => {
    const {shop} = this.props.route.params;
    if (this.props.user.phoneNumber) {
      this.props.navigation.navigate('Paiement', {shop});
    } else {
      this.props.navigation.navigate('Vérification', {shop});
    }
  };

  getProduct = item => {
    const products = [...this.props?.products];
    return products.find(it => it.id == item.id);
  };

  getMenu = item => {
    const menus = [...this.props?.menus];
    return menus.find(it => it.id == item.id);
  };

  render() {
    const {
      event,
      total,
      menus,
      products,
      addMenu,
      removeMenu,
      addProduct,
      removeProduct,
      quantities,
      quantitiesMenu,
      hideCart,
    } = this.props;
    const {colors, dark} = this.props.theme;
    return (
      <View style={[styles.container, {backgroundColor: colors.background}]}>
      
        <TouchableOpacity
          onPress={() => {
            hideCart();
          }}
          style={{
            alignItems: 'flex-end',
            paddingTop: 30,
            paddingRight: 10,
            paddingBottom: 20,
          }}>
          <Text style={{fontWeight: 'bold', fontSize: 20, color: '#008379'}}>
            Retour
          </Text>
        </TouchableOpacity>
        <ScrollView
          style={[styles.subContainer, {backgroundColor: colors.background}]}>
          <View style={styles.shopContainer}>
            <View>
              {event?.poster?.url && (
                <Image
                  style={styles.shopImage}
                  source={{uri: event?.poster?.url}}
                />
              )}
            </View>
            <View style={styles.shopTitle}>
              <Text style={[styles.shopTitleText, { color: colors.text }]}>
                  {event?.fields?.e_title}
              </Text>
              <View>
                <TextInput 
                placeholder='Ajouter une note'
                style={{ 
                  minWidth: '100%', 
                  height: 150, 
                  backgroundColor: '#ffffff88', 
                  textAlignVertical: 'top', 
                  marginVertical: 10, 
                  borderRadius: 10, 
                  padding: 10, }} multiline onChangeText={notes => this.setState({ notes })} value={this.state.notes} />
                </View>
            </View>
          </View>
          <View>
            <View
              style={[
                styles.titleContainer,
                {backgroundColor: dark ? '#34495e' : '#f8f9fa'},
              ]}>
              <View style={{width: width * 0.4}}>
                <Text
                  style={[
                    styles.titleText,
                    {color: dark ? colors.text : '#666'},
                  ]}>
                  Article
                </Text>
              </View>
              <View style={{width: width * 0.3}}>
                <Text
                  style={[
                    styles.titleText,
                    {color: dark ? colors.text : '#666'},
                  ]}>
                  Quantité
                </Text>
              </View>
              <View style={{width: width * 0.2}}>
                <Text
                  style={[
                    styles.titleText,
                    {color: dark ? colors.text : '#666'},
                  ]}>
                  Prix
                </Text>
              </View>
            </View>
            <View style={[styles.productsContainer]}>
              {[...quantities, ...quantitiesMenu]
                .filter(item => item?.quantity > 0)
                .map((item, index) => (
                  <Product
                    details={false}
                    theme={this.props.theme}
                    arr={[
                      ...iterableArray(quantities),
                      ...iterableArray(quantitiesMenu),
                    ]}
                    key={index}
                    item={item}
                    remove={item?.menu ? removeMenu : removeProduct}
                    add={item?.menu ? addMenu : addProduct}
                    it={item?.menu ? this.getMenu(item) : this.getProduct(item)}
                    products={products}
                    menus={menus}
                  />
                ))}
            </View>
            <View
              style={[
                styles.totalContainer,
                {backgroundColor: dark ? '#34495e' : '#f8f9fa'},
              ]}>
              <View style={{width: width * 0.7}}>
                <Text
                  style={[
                    styles.titleText,
                    {color: dark ? colors.text : '#666'},
                  ]}>
                  Sous total
                </Text>
              </View>
              <View style={{width: width * 0.2}}>
                <Text style={{color: colors.text}}>
                  {total.toFixed(2).replace('.', ',')}€
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
        <View style={styles.subContainerTwo}>
          <View style={{flexDirection: 'row', padding: 10}}>
            <Text
              style={{width: width * 0.8, fontSize: 18, color: colors.text}}>
              Total de la commande
            </Text>
            <Text
              style={{fontSize: 18, fontWeight: 'bold', color: colors.text}}>
              {total.toFixed(2).replace('.', ',')}€
            </Text>
          </View>
          <View style={{alignItems: 'center'}}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                this?.props?.goToPay(this.state.notes);
              }}>
              <Text style={styles.buttonText}>Valider la commande</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
  subContainer: {
    position: 'relative',
    backgroundColor: '#f9f9f9',
    height: height * 0.8,
  },
  subContainerTwo: {
    height: height * 0.2,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  shopImage: {
    width: width * 0.2,
    height: width * 0.25,
    borderRadius: 6,
  },
  shopContainer: {
    padding: 20,
    flexDirection: 'row',
  },
  titleContainer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  titleText: {
    fontSize: 14,
    color: '#666',
  },
  shopTitle: {
    paddingLeft: 20,
    width: width * 0.6,
  },
  shopTitleText: {
    fontSize: 18,
  },
  shopAddressText: {
    fontSize: 12,
    color: '#555',
    paddingLeft: 5,
  },
  shopAddress: {
    flexDirection: 'row',
    marginTop: 10,
  },
  productsContainer: {
    backgroundColor: '#fff',
  },
  productImage: {
    width: width * 0.15,
    height: width * 0.15,
  },
  product: {
    padding: 10,
    flexDirection: 'row',
  },
  productDescription: {
    width: width * 0.4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  addButtons: {
    flexDirection: 'row',
  },
  totalContainer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 10,
  },
  button: {
    height: 30,
    backgroundColor: 'rgb(214, 238, 236)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    margin: 5,
    padding:3
  },

  buttonText: {
    color: '#008379',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default CartScreen;
