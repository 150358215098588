import React, {memo, useCallback} from 'react';
import {Animated, StyleSheet, Image, View} from 'react-native';
import ThemeUtils from '../../../ultis/themeUtils';
import Color from '../../../ultis/color';
// import LinearGradient from 'react-native-linear-gradient';



const Header = memo((props) => {
  const opacity = props.scrollY.interpolate({
    inputRange: [0, 200],
    outputRange: [0, 1],
  });
  const headerTitleOpacity = props.scrollY.interpolate({
    inputRange: [0, 200],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  console.log(opacity)
  return (
    <Animated.View style={[styles.headerStyle, {
      opacity: opacity,
      flex: 1,
      ...StyleSheet.absoluteFillObject,
      zIndex: 100,
      backgroundColor: 'rgb(73, 85, 91)',
      paddingHorizontal: 10
    }]}>
    
      {/* <Animated.Text
        style={[
          styles.headerTitle,
          {
            opacity: headerTitleOpacity,
          },
        ]}>
        {props.title}
      </Animated.Text> */}
       <Animated.View style={[styles.headerStyle, {
      opacity: headerTitleOpacity,

    }]}>
       <Image 
        source={require('../../../img/logo.png')} 
        style={{height: 25, width:100}}/>
    </Animated.View>
      
    </Animated.View>
  );
});
export default Header;
const styles = StyleSheet.create({
  headerLeftIcon: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: ThemeUtils.relativeWidth(4),
    zIndex: 2,
  },
  headerRightIcon: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: ThemeUtils.relativeWidth(4),
    zIndex: 2,
  },
  headerStyle: {
    height: ThemeUtils.APPBAR_HEIGHT ,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
    position: 'absolute',
  },
  headerTitle: {
    textAlign: 'center',
    justifyContent: 'center',
    color: Color.HEADER_TEXT_COLOR,
    fontSize: ThemeUtils.fontNormal,
    flex: 1,
    zIndex: 2,
  },
});
