import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

// @ts-ignore
function SvgComponent(props) {
  return (
    <Svg viewBox="0 0 80 80" width={80} height={80} {...props}>
        <Path d="M73.9394 33.8474C75.5468 33.8474 77.0883 33.1992 78.2249 32.0453C79.3615 30.8915 80 29.3266 80 27.6948C80 26.063 79.3615 24.4981 78.2249 23.3443C77.0883 22.1904 75.5468 21.5422 73.9394 21.5422H63.0909L65.6364 7.26817C65.9257 5.66087 65.5742 4.00273 64.6593 2.65851C63.7443 1.31429 62.3408 0.394104 60.7576 0.100385C59.1743 -0.193334 57.541 0.163473 56.2168 1.09231C54.8927 2.02115 53.9863 3.44593 53.697 5.05323L50.6667 21.5422H35.8182L38.3636 7.26817C38.5069 6.47231 38.4943 5.65561 38.3267 4.86468C38.159 4.07376 37.8396 3.3241 37.3865 2.65851C36.9335 1.99292 36.3558 1.42443 35.6863 0.985508C35.0169 0.546585 34.2688 0.24582 33.4848 0.100385C32.7009 -0.04505 31.8964 -0.032306 31.1173 0.137889C30.3382 0.308084 29.5997 0.632397 28.9441 1.09231C27.62 2.02115 26.7136 3.44593 26.4242 5.05323L23.3939 21.5422H10.303C8.69566 21.5422 7.15412 22.1904 6.01753 23.3443C4.88095 24.4981 4.24242 26.063 4.24242 27.6948C4.24242 29.3266 4.88095 30.8915 6.01753 32.0453C7.15412 33.1992 8.69566 33.8474 10.303 33.8474H21.303L19.0909 46.1526H6.06061C4.45323 46.1526 2.91169 46.8008 1.77511 47.9547C0.638527 49.1085 0 50.6734 0 52.3052C0 53.937 0.638527 55.5019 1.77511 56.6557C2.91169 57.8096 4.45323 58.4578 6.06061 58.4578H16.9091L14.3636 72.7318C14.0743 74.3391 14.4258 75.9973 15.3407 77.3415C16.2557 78.6857 17.6592 79.6059 19.2424 79.8996C20.8257 80.1933 22.459 79.8365 23.7832 78.9077C25.1073 77.9789 26.0137 76.5541 26.303 74.9468L29.3333 58.4578H44.2727L41.7273 72.7318C41.4379 74.3391 41.7894 75.9973 42.7044 77.3415C43.6193 78.6857 45.0228 79.6059 46.6061 79.8996C48.1893 80.1933 49.8227 79.8365 51.1468 78.9077C52.4709 77.9789 53.3773 76.5541 53.6667 74.9468L56.697 58.4578H69.697C71.3043 58.4578 72.8459 57.8096 73.9825 56.6557C75.119 55.5019 75.7576 53.937 75.7576 52.3052C75.7576 50.6734 75.119 49.1085 73.9825 47.9547C72.8459 46.8008 71.3043 46.1526 69.697 46.1526H58.697L60.9091 33.8474H73.9394ZM46.3636 46.1526H31.4242L33.6364 33.8474H48.5758L46.3636 46.1526Z" fill="white"/>
    </Svg>
  );
}

const SvgUn_follow = React.memo(SvgComponent);
export default SvgUn_follow;
