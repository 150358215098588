const Url = {
    wikmit: 'https://wikmit.com/'
}

export const T_URLS = [
    {
        "merchant_name": 'Auchan',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fbilletterie_auchan_illicado5db6ff35506b5.jpeg?alt=media&token=b071fb73-96d0-4342-80d9-782b57320b48",
        "source": "Ticketmaster",
        "url": "https://billetterie.auchan.fr"
    },
    {
        "merchant_name": 'Carrefour',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fimage_369.png?alt=media&token=684b4ad8-c266-47fc-b67a-9373d7253125",
        "source": "Ticketmaster",
        "url": "https://www.spectacles.carrefour.fr"
    },
    {
        "merchant_name": 'Ticketmaster',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fticketmaster.png?alt=media&token=11850f4f-4750-4dc1-b2c7-c646437f9243",
        "source": "Ticketmaster",
        "url": 'https://www.ticketmaster.fr'
    },
    {
        "merchant_name": 'Cultura',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Flogo-Cultura.png?alt=media&token=f889204e-e9fc-4be2-bd0d-70e0fda9068a",
        "source": "Ticketmaster",
        "url": "https://billetterie.cultura.com"
    },
    {
        "merchant_name": 'Showroomprive',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fgetcatalogimage.jpeg?alt=media&token=a8f71d1e-316d-4a06-9472-1b37d7bd46d1",
        "source": "Ticketmaster",
        "url": "https://www.loisirs.showroomprive.com"
    },
    {
        "merchant_name": 'Cdiscount',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fcdiscount.png?alt=media&token=ebede19a-35d9-4ff0-bd3f-a1319a57581b",
        "source": "Ticketmaster",
        "url": "https://tickets.cdiscount.com"
    },
    {
        "merchant_name": 'U',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fu.png?alt=media&token=882dc006-3b52-4f81-9a03-9c5059186a4f",
        "source": "FranceBillet",
        "url": "https://magasinsu.francebillet.com/place-spectacle/ticket-evenement"
    },
    {
        "merchant_name": 'FranceBillet',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fte%CC%81le%CC%81chargement.png?alt=media&token=fc8d80ae-0df4-4063-8611-69008cbb4aee",
        "source": "FranceBillet",
        "url": "https://www.francebillet.com/billet-sortie/acheter"
    },
    {
        "merchant_name": 'Fnac',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Ffnac.png?alt=media&token=4507ed85-7a04-4c66-828e-39739ad0e6ff",
        "source": "FranceBillet",
        "url": "https://www.fnacspectacles.com/place-spectacle/ticket-evenement"
    },
    {
        "merchant_name": 'SeeTickets',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fseetickets.png?alt=media&token=b626617e-c07e-4a11-a712-43e91cae5e27",
        "source": "SeeTickets",
    },
    
  ]

export const TICKETING_URLS = [
    {
        "merchant_name": 'Carrefour',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fimage_369.png?alt=media&token=684b4ad8-c266-47fc-b67a-9373d7253125",
        "source": "Ticketmaster",
        "url": "https://www.spectacles.carrefour.fr"
    },
    {
        "merchant_name": 'Cdiscount',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fcdiscount.png?alt=media&token=ebede19a-35d9-4ff0-bd3f-a1319a57581b",
        "source": "Ticketmaster",
        "url": "https://tickets.cdiscount.com"
    },
    {
        "merchant_name": 'Fnac',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Ffnac.png?alt=media&token=4507ed85-7a04-4c66-828e-39739ad0e6ff",
        "source": "FranceBillet",
        "url": "https://www.fnacspectacles.com/place-spectacle/ticket-evenement"
    },
    {
        "merchant_name": 'SeeTickets',
        "image": "https://firebasestorage.googleapis.com/v0/b/wikmit-c5cad.appspot.com/o/logo%2Fseetickets.png?alt=media&token=b626617e-c07e-4a11-a712-43e91cae5e27",
        "source": "SeeTickets",
    },
    
  ]

export default Url