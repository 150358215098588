import React, {memo} from 'react';
import {TouchableOpacity, StyleSheet, View, ViewStyle, Text} from 'react-native';

const SpeRadio = memo((props) => {
  return (
    <TouchableOpacity
      style={[styles.container, props.style, {borderColor: props.borderColor}]}
      onPress={props.onPress}>
      <View style={{backgroundColor: props.isCheck ? "#008379" : "#ccc", width: props?.size ? props?.size : 20, height: props?.size ? props?.size : 20, borderRadius: 50, justifyContent: 'center', alignItems: 'center'}}>
        <Text style={{color: props?.isCheck ? "#ffffff" : "#000000", fontWeight: "bold"}}>{props.sign}</Text>  
      </View>       
    </TouchableOpacity>
  );
});
export default SpeRadio;
const styles = StyleSheet.create({
  container: {
    borderRadius: '50px',
    height: '24px',
    width: '24px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    height: '16px',
    width: '16px',
    borderRadius: '2px',
  },
});
