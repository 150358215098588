import firebase from "firebase/compat/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check"

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyC5XJgTm70_-B-Y3ZVt-RZG9ZeNMs19zDQ",
    authDomain: "wikmit-c5cad.firebaseapp.com",
    databaseURL: "https://wikmit-c5cad-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "wikmit-c5cad",
    storageBucket: "wikmit-c5cad.appspot.com",
    messagingSenderId: "485043730544",
    appId: "1:485043730544:web:bd5578ba4db1d1a7a79cb5",
    measurementId: "G-GTMJDLD4PV"
})
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// const init = 
initializeAppCheck(firebaseApp, {
provider: new ReCaptchaEnterpriseProvider('6Le16DoeAAAAAIIzJcBn8FxmE26D2keaFnbBSJew'),

// Optional argument. If true, the SDK automatically refreshes App Check
// tokens as needed.
isTokenAutoRefreshEnabled: true
});



export {firebaseApp}