import React, {memo, useRef, useEffect} from 'react';
import {
  NavigationContainer, 
  DefaultTheme,
  DarkTheme
} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import ROUTES from './ultis/routes';

import {StatusBar, Animated} from 'react-native';

import Restaurant from './screens/Restaurant';

import FlashMessage from "react-native-flash-message";

import { firebaseApp } from './backend/firebase';


const Stack = createStackNavigator();


const Main = memo(() => {
  
  const initTheme =  DefaultTheme 
  const ref = useRef();
  // Handle user state changes


  return (
    <NavigationContainer 
    theme={initTheme}
    ref={ref} 
    >
      <StatusBar
        barStyle={'light-content'}
        translucent={true}
        backgroundColor={'transparent'}
      />
      
        
        <Stack.Navigator
        >
      
         <Stack.Screen
          name={ROUTES.Restaurant}
          component={Restaurant}
          options={{headerShown: false}}
        />

      </Stack.Navigator>
      
      <FlashMessage duration={9000} position="top" floating={true} />
    </NavigationContainer>
  );
});

export default Main;
