const ROUTES = {
  Walkthrough: 'Walkthrough',
  Password: 'Password',
  Register: 'Register',
  SelectCity: 'SelectCity',
  SelectHashtag: 'SelectHashtag',
  SearchCity: 'SearchCity',
  //Main Tab
  MainBottomTab: 'MainBottomTab',

  TabPlan: 'TabPlan', //tab 1,
  EvezForUStack: 'EvezForUStack',
  EvezForYou: 'EvezForYou',
  EvezTrending: 'EvezTrending',
  FilterEvez: 'FilterEvez',
  FilterEvezNews: 'FilterEvezNews',
  FilterGeoloc: 'FilterGeoloc',
  ListAttending: 'Attending',
  EventAroundYou: 'Autour de vous',
  EventDetail: 'Détails',
  TicketDetail: 'Tarifs',
  SAT: 'SAT\n' + '24 MAR',
  SUN: 'SUN\n' + '25 MAR',
  MON: 'MON\n' + '26 MAR',
  PurchaseDetail: 'Purchase Detail',
  EventDetailMap: 'EventDetailMap',
  Routes: 'Routes',
  EventDetailRateComment: 'EventDetailRateComment',
  AllEventAroundYou: 'AllEventAroundYou',
  Cart: 'Panier',
  GroupedCart: 'Paiements en groupe',

  StackSearchEvents: 'StackSearchEvents', //tab2
  TabSearchEvents: 'TabSearchEvents',

  EvezNews: 'EvezNews', //tab 3
  NewDetail: 'NewDetail',
  SearchNews: 'SearchNew',

  TabPeople: 'TabPeople', //tab 4
  StackPeople: 'StackPeople',
  MaybeYouKnow: 'Maybe You Know',
  Explorer: 'Explorer',
  SearchPeople: 'SearchPeople',
  PeopleProfile: 'PeopleProfile',
  FavOrganizers: 'Suivis',
  Chat: 'Chat',

  TabProfile: 'TabProfile',
  StackProfile: 'StackProfile',
  About: 'About', //tab5
  ProfileActivity: 'Activity',
  ProfileTickets: 'Tickets',
  EventOptions: 'C\'est aujourd\'hui',
  Restaurant: 'Wikmit - Restaurant',
  Restaurants: 'Restaurants',
  ProfileSaved: 'Sauvegardés',
  Notification: 'Notification',
  Inbox: 'Inbox',
  Rewards: 'Rewards',
  TabFollowers: 'Connection',
  Followers: 'Followers',
  Following: 'Following',
  RevealedPlace: 'Lieu tenu secret',
  BillGrouped: 'Code à partager',
  Settings: 'Paramètres',
  Partnership: 'Partenaires'
};

export default ROUTES;
