import React, {memo} from 'react';
import {TouchableOpacity, StyleSheet, View, ViewStyle} from 'react-native';

const CheckBox = memo((props) => {
  return (
    <TouchableOpacity
      style={[styles.container, props.style, {borderColor: props.borderColor}]}
      onPress={props.onPress}>
      {props.isCheck ? (
        <View
          style={[
            styles.circle,
            props.styleOfCircle,
            {backgroundColor: props.checkedColor},
          ]}
        />
      ) : null}
    </TouchableOpacity>
  );
});
export default CheckBox;
const styles = StyleSheet.create({
  container: {
    height: '24px',
    width: '24px',
    borderRadius: '2px',
    borderWidth: '1px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    height: '16px',
    width: '16px',
    borderRadius: '2px',
  },
});
